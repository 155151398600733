import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";
import { Link } from "react-router-dom";

const WhyOctathorn = () => {


  const handleScrollToDetails = (event) => {
    event.preventDefault();
    document.querySelector('#process-is-easy').scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <Grid
      container
      sx={{
        padding: {
          xs: "0.9rem 1.5rem",
          sm: "4.125rem 1.5rem",
          lg: "4.125rem 5.5rem",
        },
        backgroundColor: "#ffffff",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: "3rem",
          padding: "1rem 1rem",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#1B2231",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontSize: { lg: "40px", md: "38px", sm: "32px", xs: "20px" },
            fontWeight: 600,
            fontFamily: "Montserrat",
            width: "100%",
          }}
        >
          Why Octathorn?
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontSize: { lg: "1rem", md: "1rem", sm: "0.875rem", xs: "0.75rem" },
            color: "#4c5a67",
            fontWeight: 400,
            fontFamily: "Inter",
            paddingTop: 2,
            textAlign: "center",
          }}
        >
          At Octathorn, we don't just create technology - we create possibilities. With a focus on innovation and excellence, we empower our team to think creatively, tackle challenges, and make an impact. From groundbreaking software development to advanced hardware engineering, we're leading the way in a constantly evolving tech landscape.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0rem", // Adjust spacing as needed
        }}
      >
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              ...ActionButtonStyle,
              width: { lg: "170px", md: "170px", sm: "130px", xs: "130px" },
            }}
            className="bttn"
            onClick={handleScrollToDetails}
          >
            <span className="btt">Learn More</span>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default WhyOctathorn;
