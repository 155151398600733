import React from "react";
import Avatar from "@mui/material/Avatar";
import "./EngagementModel.css";
import "../UI/Buttons/ButtonHover.css";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useMediaQuery
} from "@mui/material";
import { Box, height, padding } from "@mui/system";
import tag from "../../Assests/tag.svg";
import hiring from "../../Assests/hiring.svg";
import time from "../../Assests/time-management.svg";
import GalleryComponent from "./GalleryComponent";

const OurTeamAchievements = () => {
  const backgroundColors = ["#319AC7", "#0E4366"];
  const isMobileView = useMediaQuery("(max-width: 480px)");
  const isTabletView = useMediaQuery("(max-width: 768px)");

  return (
    <Grid
      container
      sx={{
        padding: {
          lg: "2rem 4rem",
          md: "2rem 0rem",
          sm: "2rem 0rem",
          xs: "1rem 1rem"
        },
        backgroundColor: "#F6F6F6"
      }}
    >
      {/* First Section */}
      <Grid
        item
        xs={12}
        sx={{
        
          padding: "1rem 1rem",
          mt: "2rem"
          // border: "1px solid red",
        }}
      >
        <br />
        <br />
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#181818",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontSize: { lg: " 2rem", md: "2rem", sm: "1.5rem", xs: "1.5rem" },
            fontWeight: 600,
            fontFamily: "Montserrat",
            Width: "40%"
          }}
        >
          Together We Achieve More
        </Typography>

       
      </Grid>


      <Grid
  item
  xs={12}
  sx={{
    marginBottom: "3rem",
    padding: "1rem 1rem",
    display: "flex", // Ensure Flexbox for alignment
    justifyContent: "center", // Center horizontally
    alignItems: "center" // Center vertically
  }}
>
  <Typography
    variant="subtitle2"
    component="p"
    color="#4C5A67"
    sx={{
      textAlign: "center",
      fontSize: { lg: "17px", sm: "14px", xs: "14px" },
      fontWeight: 400,
      fontFamily: "Inter",
      padding: 2,
      width: { lg: "60%" , xs: "90%" }
    }}
  >
    With diverse expertise and a unified purpose, our team works hand-in-hand to transform innovative ideas into impactful solutions, driving progress and building a future where technology connects and empowers all.
  </Typography>
</Grid>


     <GalleryComponent/>

      {/* Second Section */}

      {/* 3rd Section */}

      {/* <Grid
        item
        xs={12}
        sx={{
          marginTop: "3rem",
          //   padding: "1rem 1rem",
          // border: "1px solid green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <a
          href="/contact"
          style={{ textDecoration: "none", position: "relative" }}
        >
          <Button sx={ActionButtonStyle} className="bttn">
            <span className="btt">Meet the team</span>
          </Button>
        </a>
      </Grid> */}

    </Grid>
  );
};

export default OurTeamAchievements;
