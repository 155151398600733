import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import TechnologyMainSub from './TechnologyMainSub';
import TechnologiesProjectDivision from './TechnologiesProjectDivision';

import Sidebar from './Sidebar';

import EastIcon from '@mui/icons-material/East';

const Parenttechnologies = (state) => {
    const { heading } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTab = useMediaQuery('(max-width:800px)');


    //console.log("technology state ||||||||||||" , state)

    // //console.log("heading in technology |||||||||||||, ", state?.state?.techState?.[0].TechHeading);

    let encodedHeading = state?.state?.techState?.[0]?.TechHeading 
    ? state.state.techState[0].TechHeading 
    : state?.state?.[0]?.TechHeading;

    //console.log("heading in technology |||||||||||||2, ", encodedHeading);
  
  let decodedHeading = decodeURIComponent(encodedHeading); // "product development services"
  let formattedHeading = decodedHeading.replace(/\s+/g, '').toLowerCase(); // Removes spaces and makes lowercase
  //console.log(formattedHeading); // Output: "productdevelopmentservices"

    const handleClick = () => {
        // navigate(`/technologiesmain/${formattedHeading}`); // Specify the route you want to navigate to

        const url = `/technologiesmain/${formattedHeading}`;
    
    // First, navigate to the new route
    navigate(url);
    
    // Force a full page refresh by updating the window's location
    window.location.href = url;
    };

    return (
        <Box sx={{ flexGrow: 1, padding: 0,  width: '100vw', marginLeft: "0" }}>
            <Grid container spacing={0} 
            sx={{ backgroundColor: "ffffff",
                  display: 'flex', 
                  flexDirection: 'row', 
                  margin: 0 ,
                  
                  
                  }}>
                {/* Sidebar Division */}
                {!isMobile && !isTab && ( // Only render Sidebar if not in mobile view
                    <Grid item xs={16} sm={3} md={2.2} sx={{ position: 'relative', padding: "0px", width: "100%" , height: '527px'}}>
                        <Sidebar />
                    </Grid>
                )}

                {/* Second Division */}
                <Grid item xs={12} sm={4} md={3.8} 
                sx={{ backgroundColor: '#f0f0f0', 
                padding: '0rem', 
                height: { lg: "511px", md: "511px", sm: "560px", xs: "560px" },
                marginTop: "0px", 
                position: 'relative', 
                left: 'auto' }}>
                    <TechnologyMainSub state={state || location.state} />
                </Grid>

                {/* Third Division */}
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ backgroundColor: '#ffffff', padding: -7, height: "511px", marginTop: "0px", overflowY: "auto" }}>
                        <Grid item xs={12} sx={{ padding: "0rem 2rem", height: "60px", display: 'flex', flexDirection: 'column' }}>
                            <TechnologiesProjectDivision state={state || location.state}/>
                            <Box
  sx={{
    position: "absolute",       // Make the box positioned absolutely
    bottom: "1rem",             // Align to the bottom of the container
    right: "1rem",              // Align to the right of the container
    cursor: "pointer",
    color: "black",             // Set text color
    backgroundColor: "#ffffff", // Set background color
    "&:hover": {
      textDecoration: "underline", // Underline on hover
    },
    padding: "0.5rem",           // Optional padding for a cleaner look
    // borderRadius: "4px",         // Optional border radius for a smooth corner
    // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",  // Optional shadow for a floating effect
  }}
  onClick={handleClick} // Keep the onClick event
>
  <Typography
    variant="body2"
    sx={{ fontSize: "0.875rem", fontWeight: 600 }}
  >
    View More
  </Typography>
</Box>

                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Parenttechnologies;
