//.........

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { animateScroll as scroll } from "react-scroll";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Popover,
  Tooltip,
  Card,
  Paper,
  Collapse,
  ListItemButton,
  Button,
  Divider
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import MessageIcon from "@mui/icons-material/Message";

import {
  ProductService,
  EmbeddedService,
  IotService,
  RDService,
  PcbService,
  TechnicalService,
  WebService,
  MobileService,
  ErpService
} from "../ServicesComponents/SecondServiceComps/ServiceData";
import {
  DatabaseTech,
  FrontEndTech,
  BackEndTech,
  MobTech,
  EmbeddedTech,
  PcbTech,
  IotTech
} from "../Technologies/TechnologyData";
import { styled } from "@mui/system";
import Logo from "../../Assests/OctathornLogo.png";
import CustomeButton from "../UI/Buttons/RounedButton";
import MenuIcon from "@mui/icons-material/Menu";
import ClosIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StartServices from "../../Pages/Services";
import Industries from "../../Pages/Industries";
import StartTechnologies from "../../Pages/Technologies";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";
import { NavbarButtonStyle } from "../UI/Buttons/NavbarButton";
// pl={{xl:'30%',lg:'25%',md:'10%'}}

export const TechlistItems = [
  "Embedded System",
  "Internet of things",
  "PCB",
  "Front End",
  "Back End",
  "Mobile App Development",
  "Database"
];
export const getTechnologyState = (tech) => {
  let techState;
  let techuseState;

  switch (tech) {
    // Embedded System
    case "Embedded System":
      techState = EmbeddedTech;
      techuseState = "Raspberry Pi";
      break;

    // Internet of Things (ioT)
    case "Internet of things":
      techState = IotTech;
      techuseState = "Azure IoT Hub";
      break;

    // PCB
    case "PCB":
      techState = PcbTech;
      techuseState = "Altium";
      break;

    // FrontEnd
    case "Front End":
      techState = FrontEndTech;
      techuseState = "Angular";
      break;

    // BackEnd
    case "Back End":
      techState = BackEndTech;
      techuseState = "Node JS";
      break;

    // MobileApp Development
    case "Mobile App Development":
      techState = MobTech;
      techuseState = "Flutter";
      break;

    // Database
    case "Database":
      techState = DatabaseTech;
      techuseState = "FireBase";
      break;

    // Add other cases for different technologies if needed
    default:
      techState = null; // Default state if no match
  }

  return { techState, techuseState };
};

const IndustriesListItems = [
  "Health care",
  "Finance",
  "Defence",
  "Public Sector",
  "Manufacturing",
  "Telecom",
  "Ecommerce"
];

const listItems = [
  "Product Development",
  "Embedded System",
  "IoT Platform Development",
  "Research & Development",
  "PCB",
  "Technical Consultation",
  "Web Application",
  "Mobile Application",
  "ERP"
];

function Nav() {
  const [isStartServicesVisible, setIsStartServicesVisible] = useState(false);
  const startServicesRef = useRef(null);

  const [isStartIndustriesVisible, setIsStartIndustriesVisible] =
    useState(false);
  const startIndustriesRef = useRef(null);

  const [isStartTechnologiesVisible, setIsStartTechnologiesVisible] =
    useState(false);
  const startTechnologiesRef = useRef(null);

  const handleClickNavlink = (data) => {
    navigate(`/`); // Correct usage of template literals
  };

  // Handle clicks outside of the dropdowns
  const handleClickOutside = (event) => {
    if (
      startServicesRef.current &&
      !startServicesRef.current.contains(event.target)
    ) {
      setIsStartServicesVisible(false);
    }
    if (
      startIndustriesRef.current &&
      !startIndustriesRef.current.contains(event.target)
    ) {
      setIsStartIndustriesVisible(false);
    }
    if (
      startTechnologiesRef.current &&
      !startTechnologiesRef.current.contains(event.target)
    ) {
      setIsStartTechnologiesVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  const isMobileView = useMediaQuery("(max-width: 480px)");
  const isTabletView = useMediaQuery("(max-width: 780px)");
  const mobileViewMinWidth = useMediaQuery("(min-width: 480px)");
  const [technologyState, setTechnologyState] = useState({
    techState: null,
    techuseState: null
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  //serive popover handeling
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
  ))({
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0
    //color:'#F6F6F6'
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const IndustriesListItems = [
    "Health care",
    "Finance",
    "Defence",
    "Public Sector",
    "Manufacturing",
    "Telecom",
    "Ecommerce"
  ];
  const listItems = [
    "Product Development",
    "Embedded System",
    "IoT platform development",
    "Research and Development",
    "PCB",
    "Technical Consultation",
    "Web Application",
    "Mobile Application",
    "ERP"
  ];

  const TechDataArray = [
    EmbeddedTech,
    IotTech,
    PcbTech,
    FrontEndTech,
    BackEndTech,
    MobTech,
    DatabaseTech
  ];

  //serive popover navigation handeling....
  // Create an array of service data
  const serviceDataArray = [
    ProductService,
    EmbeddedService,
    IotService,
    RDService,
    PcbService,
    TechnicalService,
    WebService,
    MobileService,
    ErpService
  ];

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setDrawerOpen(!drawerOpen);
  };
  // Initialize the index state variable to null
  const [index, setIndex] = useState(null);
  const [activeLink, setActiveLink] = useState("/");

  function handleTechListItemClick(item) {
    const { techState, techuseState } = getTechnologyState(item);

    if (techState) {
      const stateObject = {
        techState: techState,
        techuseState: techuseState
      };

      const headingWithoutSpaces = techState[0].TechHeading[0]
        .replace(/\s/g, "")
        .toLowerCase();
      const itemWithoutSpaces = techState[2].TechList[0]
        .replace(/\s/g, "")
        .toLowerCase();

      // Update the URL when the user clicks on an item
      navigate(`/technologiesmain/${headingWithoutSpaces}`, {
        state: stateObject
      });

      // navigate('.', { state: stateObject }); // Pass state without changing the URL
      // navigate('.', { state: stateObject }); // Pass state without changing the URL

      scroll.scrollToTop({ duration: 2000 });

      setAnchorEl(null);
      setDrawerOpen(false);
    }
  }

  const [openTechnologies, setOpenTechnologies] = useState(false);
  const handleTechnologiesClick = () => {
    setOpenTechnologies(!openTechnologies);
  };

  function handleServiceListItemClick(item) {
    const itemIndex = listItems?.findIndex((listItem) => listItem === item);
    // Update the index state variable
    setIndex(itemIndex);
    // Use the index to get the corresponding service data
    const serviceData = serviceDataArray[itemIndex];

    const headingWithoutSpaces = serviceData[0].ServiceHeading[0]
      .replace(/\s/g, "")
      .toLowerCase();
    navigate(`/servicesmain/${headingWithoutSpaces}`, {
      state: serviceData
    });
    scroll.scrollToTop({ duration: 2000 });
    setAnchorEl(null);
    //console.log(index);
    setDrawerOpen(false);
  }
  //end service popover handeling....
  //serive expand  list handeling mobile
  const [openServices, setOpenServices] = useState(false);
  const handleServicesClick = () => {
    setOpenServices(!openServices);
  };

  function handleIndustriesListItemClick(item) {
    const itemIndex = IndustriesListItems?.findIndex(
      (listItem) => listItem === item
    );
    // Update the index state variable
    setIndex(itemIndex);
    const headingWithoutSpaces = item.replace(/\s/g, "").toLowerCase();

    navigate(`/industriesmain/${headingWithoutSpaces}`, {
      // state: serviceData,
    });
    scroll.scrollToTop({ duration: 2000 });
    setAnchorEl(null);
    //console.log(index);
    setDrawerOpen(false);
  }
  //end service popover handeling....
  //serive expand  list handeling mobile
  const [openIndustries, setOpenIndustries] = useState(false);
  const handleIndustriesClick = () => {
    setOpenIndustries(!openIndustries);
  };
  //end service list handeling mobile
  const style = {
    ListItemText: {
      fontSize: { lg: "1rem", md: "0.9rem", sm: "0.8rem", xs: "0.75rem" },
      fontWeight: "400",
      color: "#4c5a67",
      transition: "transform 0.3s ease-in-out", // Add transition property for transform
      "&:hover": {
        color: "#0E4366",
        background: "#fff",
        transform: "scale(1.05)" // Grow the text to 1.1 times its original size on hover
      }
    },
    listTypography: {
      color: "#4c5a67",
      fontFamily: "Inter",
      fontSize: { lg: "1rem", md: "0.9rem", sm: "0.8rem", xs: "0.9rem" },
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      textTransform: "none",
      transition: "transform 0.3s ease-in-out",
      "&:hover": {
        transform: "translateX(5px)",
        color: "#0E4366"
      }
    }
  };
  const list = (
    <List>
      {/* <Link to="/" style={{ textDecoration: "none" }}></Link> */}
      <Link
        to="/"
        style={{ textDecoration: "none", color: "#4c5a67" }}
        onClick={handleLinkClick}
        component={ListItemButton}
      >
        <ListItem button>
          <ListItemText primary="Home" sx={style.ListItemText} />
        </ListItem>
      </Link>
      <Link
        to="/about"
        style={{ textDecoration: "none", color: "#4c5a67" }}
        onClick={handleLinkClick}
      >
        <ListItem>
          <ListItemText primary="About" sx={style.ListItemText} />
        </ListItem>
      </Link>
      {/* Serive list-Item*/}
      <ListItem button onClick={handleServicesClick}>
        <ListItemText primary="Services" sx={style.ListItemText} />
        {openServices ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openServices} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleServiceListItemClick(item)}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon
                  style={{
                    width: "1rem",
                    height: "1rem",
                    flexShrink: 0,
                    color: "#8E8E8E",
                    fontSize: "0.3rem"
                  }}
                />
              </ListItemIcon>
              <Typography sx={style.listTypography}>{item}</Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={handleIndustriesClick}>
        <ListItemText primary="Industries" sx={style.ListItemText} />
        {openIndustries ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openIndustries} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {IndustriesListItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleIndustriesListItemClick(item)}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon
                  style={{
                    width: "1rem",
                    height: "1rem",
                    flexShrink: 0,
                    color: "#8E8E8E",
                    fontSize: "0.3rem"
                  }}
                />
              </ListItemIcon>
              <Typography sx={style.listTypography}>{item}</Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={handleTechnologiesClick}>
        <ListItemText primary="Technologies" sx={style.ListItemText} />
        {openTechnologies ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openTechnologies} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {TechlistItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => handleTechListItemClick(item)}
            >
              <ListItemIcon>
                <ArrowForwardIosIcon
                  style={{
                    width: "1rem",
                    height: "1rem",
                    flexShrink: 0,
                    color: "#8E8E8E",
                    fontSize: "0.3rem"
                  }}
                />
              </ListItemIcon>
              <Typography sx={style.listTypography}>{item}</Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>

      {/* <ListItem button>
        <ListItemText primary="Technologies" sx={style.ListItemText} />
      </ListItem> */}
      {/* Your other components and routes */}
      {/* <Link
        to="/privacypolicy"
        style={{ textDecoration: "none", color: "#757575" }}
        onClick={handleLinkClick}
      >
        <ListItem button>
          <ListItemText primary="Privacy Policy" sx={style.ListItemText} />
        </ListItem>
      </Link> */}

      <Link
        to="/portfolio"
        style={{ textDecoration: "none", color: "#757575" }}
        onClick={handleLinkClick}
      >
        <ListItem button>
          <ListItemText primary="Portfolio" sx={style.ListItemText} />
        </ListItem>
      </Link>

      <Link
        to="/ourteam"
        style={{ textDecoration: "none", color: "#757575" }}
        onClick={handleLinkClick}
      >
        <ListItem button>
          <ListItemText primary="Our Team" sx={style.ListItemText} />
        </ListItem>
      </Link>

      <Link
        to="/career"
        style={{ textDecoration: "none", color: "#757575" }}
        onClick={handleLinkClick}
      >
        <ListItem button>
          <ListItemText primary="Career" sx={style.ListItemText} />
        </ListItem>
      </Link>

      <Link
        to="/contact"
        style={{ textDecoration: "none", color: "#757575" }}
        onClick={handleLinkClick}
      >
        <ListItem button sx={{ marginTop: "8%" }}>
          <CustomeButton text="Contact Us" sx={style.ListItemText} />
        </ListItem>
      </Link>
    </List>
  );
  return (
    <div style={{ minWidth: "700px" }}>
      <AppBar
        sx={{
          background: "#FFFFFF",
          // boxShadow: "none",

          borderBottom: "none",
          padding: "5px",
          boxShadow:
            "0px -1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.01)"
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            // to="/" style={{ textDecoration: "none", color: "#757575" }}

            to="/"
            style={{
              textDecoration: "none",
              color: activeLink === "/" ? "#1B2231" : "#757575",
              fontWeight: activeLink === "/" ? "bold" : "normal"
            }}
            onClick={() => {
              setActiveLink("/");
              scroll.scrollToTop({ duration: 1000 });
            }}
          >
            <img
              src={Logo}
              width={isTabletView ? "134px" : "134px"}
              alt="Octathorn technologies Hardware & Software Logo"
              style={{ marginLeft: isTabletView ? "0px" : "20px" }}
            />
          </Link>
          {!isMobileView && !isTabletView ? (
            <>
              {/* A divider line just after logo */}
              <Divider
                orientation="vertical"
                flexItem
                sx={{ bgcolor: "#e0e0e0", mx: 2 }}
              />

              <div></div>
              <Box style={{ display: "flex", marginRight: "auto" }}>
                {/* <div
                  style={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0", // Light grey background on hover
                      bodderRadius: "4px" // Optional: Add vertical padding for a larger hover area
                    }
                  }}
                >
                  <Typography
                    fontFamily={"Inter"}
                    fontWeight={400}
                    color={"#4c5a67"}
                    fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                    padding={{
                      xl: "0 15px",
                      lg: "0 15px",
                      md: "0 10px",
                      sm: "0 10px"
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#f0f0f0", // Light grey background on hover
                        bodderRadius: "4px" // Optional: Add vertical padding for a larger hover area
                      }
                    }}
                  >
                    <Link
                      to="/"
                      style={{
                        textDecoration: "none",
                        color: activeLink === "/" ? "#1B2231" : "#757575",
                        fontWeight: activeLink === "/" ? "bold" : "normal"
                      }}
                      onClick={() => {
                        setActiveLink("/");
                        scroll.scrollToTop({ duration: 1000 });
                      }}
                    >
                      Home
                    </Link>
                  </Typography>
                </div> */}

                {/* Services Navlink */}
                {/* <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px",
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      color: isStartServicesVisible ? "#1B2231" : "#757575",
                      fontWeight: isStartServicesVisible ? "bold" : "normal",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setActiveLink(`/services/Productdevelopment`);
                      handleClickNavlink();
                      setIsStartServicesVisible(!isStartServicesVisible);
                    }}
                  >
                    Services
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </Link>
                </Typography> */}

                {/* Background shadow when the dropdown is visible, but only below the navbar */}
                {/* {isStartServicesVisible && (
                  <>
                    
                    <div
                      style={{
                        position: "fixed",
                        top: "73px", // This ensures the overlay starts just below the navbar
                        left: 0,
                        width: "100%",
                        height: "calc(100vh - 70px)", // Only cover the area below the navbar
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark background
                        zIndex: 1000, // Lower than the dropdown
                      }}
                      onClick={() => setIsStartServicesVisible(false)} // Clicking the overlay closes the dropdown
                    />

           
                    <div
                      ref={startServicesRef}
                      style={{
                        position: "absolute",
                        top: "68px",
                        left: "-10px",
                        width: "101.3%",
                        zIndex: 1001,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <StartServices />
                    </div>
                  </>
                )} */}

                {/* Industries Navlink */}
                {/* <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px",
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Link
                    to="/"
                    // to={`/industries/healthcare`}
                    style={{
                      textDecoration: "none",
                      color:
                        activeLink === "/industries/healthcare"
                          ? "#1B2231"
                          : "#757575",
                      fontWeight:
                        activeLink === "/industries/healthcare"
                          ? "bold"
                          : "normal",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setActiveLink(`/industries/healthcare`);
                      setIsStartIndustriesVisible(!isStartIndustriesVisible);
                    }}
                  >
                    Industries
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </Link>
                </Typography>

                {isStartIndustriesVisible && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: "73px", // This ensures the overlay starts just below the navbar
                        left: 0,
                        width: "100%",
                        height: "calc(100vh - 70px)", // Only cover the area below the navbar
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark background
                        zIndex: 1000, // Lower than the dropdown
                      }}
                      onClick={() => setIsStartServicesVisible(false)} // Clicking the overlay closes the dropdown
                    />
                    <div
                      ref={startIndustriesRef}
                      style={{
                        position: "absolute",
                        top: "68px",
                        left: "-10px",
                        width: "101.3%",
                        zIndex: 1001,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <Industries />
                    </div>
                  </>
                )} */}

                {/* Technologies Navlink */}
                {/* <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px",
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                      borderRadius: "4px",
                    },
                  }}
                >
                  <Link
                    to="/"
                    // to={`technologies/embeddedsystem`}
                    style={{
                      textDecoration: "none",
                      color:
                        activeLink === `technologies/embeddedsystem`
                          ? "#1B2231"
                          : "#757575",
                      fontWeight:
                        activeLink === `technologies/embeddedsystem`
                          ? "bold"
                          : "normal",
                      display: "inline-flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setActiveLink(`technologies/embeddedsystem`);
                      setIsStartTechnologiesVisible(
                        !isStartTechnologiesVisible
                      );
                    }}
                  >
                    Technologies
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </Link>
                </Typography>

                {isStartTechnologiesVisible && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        top: "73px", // This ensures the overlay starts just below the navbar
                        left: 0,
                        width: "100%",
                        height: "calc(100vh - 70px)", // Only cover the area below the navbar
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent dark background
                        zIndex: 1000, // Lower than the dropdown
                      }}
                      onClick={() => setIsStartServicesVisible(false)} // Clicking the overlay closes the dropdown
                    />
                    <div
                      ref={startTechnologiesRef}
                      style={{
                        position: "absolute",
                        top: "68px",
                        left: "-10px",
                        width: "101.3%",
                        zIndex: 1001,
                        backgroundColor: "white",
                        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <StartTechnologies />
                    </div>
                  </>
                )} */}

                {/* <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0", // Light grey background on hover
                      bodderRadius: "4px" // Optional: Add vertical padding for a larger hover area
                    }
                  }}
                >
                  <Link
                    to={`/industries/healthcare`}
                    style={{
                      textDecoration: "none",
                      color:
                        activeLink === `/industries/healthcare`
                          ? "#1B2231"
                          : "#757575",
                      fontWeight:
                        activeLink === "/ParentService" ? "bold" : "normal",
                      display: "inline-flex", // Ensures text and icon are in the same line
                      alignItems: "center"
                    }}
                    onClick={() => {
                      setActiveLink(`/industries/healthcare`);
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                  >
                    Industries
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </Link>
                
                </Typography> */}

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#757575",
                    "&:hover": {
                      // color: '#003366', // Light grey background on hover
                      // bodderRadius: '4px', // Optional: Add vertical padding for a larger hover area
                      // fontWeight: 'bold',
                    }
                  }}
                >
                  <CustomTooltip
                    style={{
                      display: "inline-flex", // Ensures text and icon are in the same line
                      alignItems: "center" // Align text and icon vertically in the middle
                    }}
                    title={
                      <React.Fragment>
                        <Card
                          style={{
                            width: "21.25rem",
                            height: "20.5rem",
                            borderRadius: "1.1875rem",
                            background: "#fff",
                            padding: "0.4rem"
                          }}
                        >
                          <Box mb={1.4375}>
                            {listItems.map((item, index) => (
                              <ListItem
                                button
                                key={index}
                                onClick={() => {
                                  handleServiceListItemClick(item);
                                  // scroll.scrollToTop({ duration: 1000 });
                                  setActiveLink(`/services/${item}`); // Add this line
                                  // setActiveLink("/servicesmain");
                                }}
                              >
                                <ListItemIcon>
                                  <ArrowForwardIosIcon
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      flexShrink: 0,
                                      color:
                                        activeLink === `/services/${item}`
                                          ? "#0E4366"
                                          : "#757575",
                                      fontSize: "0.3rem"
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography
                                  sx={{
                                    ...style.listTypography,
                                    color:
                                      activeLink === `/services/${item}`
                                        ? "#0E4366"
                                        : "#757575",
                                    fontWeight:
                                      activeLink === `/services/${item}`
                                        ? "bold"
                                        : "normal"
                                  }}
                                >
                                  {item}
                                </Typography>
                              </ListItem>
                            ))}
                          </Box>
                        </Card>
                      </React.Fragment>
                    }
                    placement="bottom"
                    interactive="true"
                  >
                    Services
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </CustomTooltip>
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#757575"
                    // '&:hover': {
                    //   color: '#003366', // Light grey background on hover
                    //   bodderRadius: '4px', // Optional: Add vertical padding for a larger hover area
                    //   fontWeight: 'bold',
                    // },
                  }}
                >
                  <CustomTooltip
                    style={{
                      display: "inline-flex", // Ensures text and icon are in the same line
                      alignItems: "center" // Align text and icon vertically in the middle
                    }}
                    title={
                      <React.Fragment>
                        <Card
                          style={{
                            width: "21.25rem",
                            height: "16.5rem",
                            borderRadius: "1.1875rem",
                            background: "#fff",
                            padding: "0.4rem"
                          }}
                        >
                          <Box mb={1.4375}>
                            {IndustriesListItems.map((item, index) => (
                              <ListItem
                                button
                                key={index}
                                onClick={() => {
                                  handleIndustriesListItemClick(item);
                                  // scroll.scrollToTop({ duration: 1000 });
                                  setActiveLink(`/industries/${item}`); // Add this line
                                }}
                              >
                                <ListItemIcon>
                                  <ArrowForwardIosIcon
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      flexShrink: 0,
                                      color:
                                        activeLink === `/industries/${item}`
                                          ? "#0E4366"
                                          : "#757575",
                                      fontSize: "0.3rem",

                                      "&:hover": {
                                        color: "#003366", // Light grey background on hover
                                        bodderRadius: "4px", // Optional: Add vertical padding for a larger hover area
                                        fontWeight: "bold"
                                      }
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography
                                  sx={{
                                    ...style.listTypography,
                                    color:
                                      activeLink === `/industries/${item}`
                                        ? "#0E4366"
                                        : "#757575",
                                    fontWeight:
                                      activeLink === `/industries/${item}`
                                        ? "bold"
                                        : "normal"
                                  }}
                                >
                                  {item}
                                </Typography>
                              </ListItem>
                            ))}
                          </Box>
                        </Card>
                      </React.Fragment>
                    }
                    placement="bottom"
                    interactive="true"
                  >
                    Industires
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </CustomTooltip>
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#757575",
                    "&:hover": {
                      // color: '#003366', // Light grey background on hover
                      // bodderRadius: '4px', // Optional: Add vertical padding for a larger hover area
                      // fontWeight: 'bold',
                    }
                  }}
                >
                  <CustomTooltip
                    style={{
                      display: "inline-flex", // Ensures text and icon are in the same line
                      alignItems: "center" // Align text and icon vertically in the middle
                    }}
                    title={
                      <React.Fragment>
                        <Card
                          style={{
                            width: "21.25rem",
                            height: "16.5rem",
                            borderRadius: "1.1875rem",
                            background: "#fff",
                            padding: "0.4rem"
                          }}
                        >
                          <Box mb={1.4375}>
                            {TechlistItems.map((item, index) => (
                              <ListItem
                                button
                                key={index}
                                onClick={() => {
                                  handleTechListItemClick(item);
                                  // scroll.scrollToTop({ duration: 1000 });
                                  setActiveLink(`/technologies/${item}`); // Add this line
                                }}
                              >
                                <ListItemIcon>
                                  <ArrowForwardIosIcon
                                    style={{
                                      width: "1rem",
                                      height: "1rem",
                                      flexShrink: 0,
                                      color:
                                        activeLink === `/technologies/${item}`
                                          ? "#0E4366"
                                          : "#757575",
                                      fontSize: "0.3rem"
                                    }}
                                  />
                                </ListItemIcon>
                                <Typography
                                  sx={{
                                    ...style.listTypography,
                                    color:
                                      activeLink === `/technologies/${item}`
                                        ? "#0E4366"
                                        : "#757575",
                                    fontWeight:
                                      activeLink === `/technologies/${item}`
                                        ? "bold"
                                        : "normal"
                                  }}
                                >
                                  {item}
                                </Typography>
                              </ListItem>
                            ))}
                          </Box>
                        </Card>
                      </React.Fragment>
                    }
                    placement="bottom"
                    interactive="true"
                  >
                    Technologies
                    <ExpandMoreIcon
                      style={{ fontSize: "1rem", marginLeft: "5px" }}
                    />
                  </CustomTooltip>
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  // sx={{
                  //   color: "#757575",
                  //   '&:hover': {
                  //     color: '#003366', // Dark blue color on hover
                  //     fontWeight: 'bold', // Make text bold on hover
                  //   },
                  // }}
                >
                  <Link
                    to="/about"
                    onClick={() => {
                      setActiveLink("/about");
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                    // sx={{
                    //   textDecoration: "none",
                    //   color: activeLink === "/about" ? '#1B2231' : "#757575", // Dark blue if active
                    //   fontWeight: activeLink === "/about" ? "bold" : "normal",
                    //   // '&:hover': {
                    //   //   color: '#003366', // Dark blue color on hover
                    //   //   fontWeight: 'bold', // Make text bold on hover
                    //   // },
                    // }}

                    style={{
                      textDecoration: "none",
                      color: activeLink === "/about" ? "#1B2231" : "#757575",
                      fontWeight: activeLink === "/about" ? "bold" : "normal",

                      "&:hover": {
                        color: "#003366", // Dark blue color on hover
                        fontWeight: "bold" // Make text bold on hover
                      }
                    }}
                  >
                    About
                  </Link>
                </Typography>

                {/* <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  // color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px",
                  }}
                  sx={{  color: "#757575",
                    '&:hover': {
                      color: '#003366', // Light grey background on hover
                      bodderRadius: '4px', // Optional: Add vertical padding for a larger hover area
                      fontWeight: 'bold',
                    },
                   }}
               
                >
                 
                  <Link
                    to="/about"
                    style={{
                      textDecoration: "none",
                      color: activeLink === "/about" ? "#1B2231" : "#757575",
                      fontWeight: activeLink === "/about" ? "bold" : "normal",
                    }}
                    onClick={() => {
                      setActiveLink("/about");
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                    sx={{  color: "#757575",
                      '&:hover': {
                        color: '#003366', // Light grey background on hover
                        bodderRadius: '4px', // Optional: Add vertical padding for a larger hover area
                        fontWeight: 'bold',
                      },
                     }}
                  >
                    About
                  </Link>
                
                </Typography> */}

                {/* <Typography
  fontFamily={"Inter"}
  fontWeight={400}
  color={"#4c5a67"}
  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
  padding={{
    xl: "0 15px",
    lg: "0 15px",
    md: "0 10px",
    sm: "0 10px",
  }}
  // sx={{
  //   color: "#757575",
  //   '&:hover': {
  //     color: '#003366', // Dark blue color on hover
  //     fontWeight: 'bold', // Make text bold on hover
  //   },
  // }}
>
  <Link
    to="/privacypolicy"
    onClick={() => {
      setActiveLink("/privacypolicy");
      scroll.scrollToTop({ duration: 1000 });
    }}
    // sx={{
    //   textDecoration: "none",
    //   color: activeLink === "/about" ? '#1B2231' : "#757575", // Dark blue if active
    //   fontWeight: activeLink === "/about" ? "bold" : "normal",
    //   // '&:hover': {
    //   //   color: '#003366', // Dark blue color on hover
    //   //   fontWeight: 'bold', // Make text bold on hover
    //   // },
    // }}

    style={{
      textDecoration: "none",
      color:
        activeLink === "/privacypolicy" ? "#1B2231" : "#757575",
      fontWeight:
        activeLink === "/privacypolicy" ? "bold" : "normal",

            '&:hover': {
        color: '#003366', // Dark blue color on hover
        fontWeight: 'bold', // Make text bold on hover
      },
        
    }}
  >
    Privacy Policy
  </Link>
</Typography> */}

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                >
                  <Link
                    to="/ourteam"
                    onClick={() => {
                      setActiveLink("/OurTeam");
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                    style={{
                      textDecoration: "none",
                      color: activeLink === "/ourteam" ? "#1B2231" : "#757575",
                      fontWeight: activeLink === "/ourteam" ? "bold" : "normal",

                      "&:hover": {
                        color: "#003366", // Dark blue color on hover
                        fontWeight: "bold" // Make text bold on hover
                      }
                    }}
                  >
                    Our Team
                  </Link>
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 15px",
                    lg: "0 15px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                >
                  <Link
                    to="/career"
                    onClick={() => {
                      setActiveLink("/career");
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                    style={{
                      textDecoration: "none",
                      color: activeLink === "/career" ? "#1B2231" : "#757575",
                      fontWeight: activeLink === "/career" ? "bold" : "normal",

                      "&:hover": {
                        color: "#003366", // Dark blue color on hover
                        fontWeight: "bold" // Make text bold on hover
                      }
                    }}
                  >
                    Career
                  </Link>
                </Typography>

                <Typography
                  fontFamily={"Inter"}
                  fontWeight={400}
                  color={"#4c5a67"}
                  fontSize={{ sm: 11, md: 16, lg: 16, xl: 16 }}
                  padding={{
                    xl: "0 100px 0 15px",
                    lg: "0 15px 0 25px",
                    md: "0 10px",
                    sm: "0 10px"
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#757575",
                    "&:hover": {
                      color: "#003366", // Light grey background on hover
                      bodderRadius: "4px", // Optional: Add vertical padding for a larger hover area
                      fontWeight: "bold"
                    }
                  }}
                >
                  <Link
                    to="/portfolio"
                    style={{
                      textDecoration: "none",
                      color:
                        activeLink === "/portfolio" ? "#1B2231" : "#757575",
                      fontWeight:
                        activeLink === "/portfolio" ? "bold" : "normal"
                    }}
                    onClick={() => {
                      setActiveLink("/portfolio");
                      scroll.scrollToTop({ duration: 1000 });
                    }}
                  >
                    Portfolio
                  </Link>
                  {/* </ScrollLink> */}
                </Typography>
              </Box>

              <div>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "#757575" }}
                  onClick={() => {
                    setActiveLink("/contact");
                    scroll.scrollToTop({ duration: 1000 });
                  }}
                >
                  {/* <Button
                    sx={{
                      width: {
                        xl: "170px",
                        lg: "170px",
                        md: "127px",
                        sm: "127px",
                        xs: "100%",
                      },
                      minWidth: {
                        xl: "170px",
                        lg: "170px",
                        md: "127px",
                        sm: "127px",
                        xs: "100%",
                      },
                      height: {
                        xl: "49px",
                        lg: "49px",
                        md: "49px",
                        sm: "37px",
                        xs: "49px",
                      },
                      color: "#172F49",
                      background: "transparent",
                      fontSize: {
                        xs: "16px",
                        sm: "13px",
                        md: "14px",
                        lg: "17px",
                        xl: "17px",
                      },
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                      lineHeight: "24px",
                      letterSpacing: "0px",
                      // textAlign: "center",
                      marginLeft: "auto",
                      // marginRight: "1%",
                      border: "1px solid #0E4366",
                      textDecoration: "none",
                      textTransform: "none",
                      boxShadow: "none",
                      padding: "20px, 36px, 20px, 36px",
                      transition: "0.25s",
                      "&:hover": {
                        backgroundColor: "#0E4366",
                        borderColor: "#0E4366",
                        color: "#F6F6F6",
                        boxShadow: "0 1em 1em -0.6em rgba(14, 67, 102, 0.7)",
                        transform: "translateY(-0.25em)",
                      },
                    }}
                  >
                    CONTACT
                 
                    <IconButton
                      onClick={() => //console.log("Message clicked")} // Add your message functionality here
                    >
                      <MessageIcon
                        style={{
                          color: "#FFFFFF", // Fill color for the icon (white)
                          stroke: "black", // Adds a black border around the icon
                          strokeWidth: 0.5, // Controls the thickness of the black border
                        }}
                      />
                    </IconButton>
                  </Button> */}

                  <Button
                    sx={{
                      ...NavbarButtonStyle, // Reusing ActionButtonStyle for consistent styles
                      width: {
                        xl: "170px",
                        lg: "170px",
                        md: "127px",
                        sm: "127px",
                        xs: "100%"
                      },
                      minWidth: {
                        xl: "170px",
                        lg: "170px",
                        md: "127px",
                        sm: "127px",
                        xs: "100%"
                      },
                      height: {
                        xl: "49px",
                        lg: "49px",
                        md: "49px",
                        sm: "37px",
                        xs: "49px"
                      },
                      fontSize: {
                        xs: "16px",
                        sm: "13px",
                        md: "14px",
                        lg: "17px",
                        xl: "17px"
                      },
                      fontWeight: 400,
                      lineHeight: "24px",
                      marginLeft: "auto"
                      // border: "1px solid #0E4366",
                      // backgroundColor: "#ffffff",
                      // color: "#172F49",
                      //   borderColor: "#172F49",

                      // padding: "20px, 36px, 20px, 36px", // Keeping custom padding
                      // "&:hover": {
                      //   backgroundColor: "#0E4366",
                      //   borderColor: "#0E4366",
                      //   color: "#F6F6F6",
                      //   boxShadow: "0 1em 1em -0.6em rgba(14, 67, 102, 0.7)",
                      //   transform: "translateY(-0.25em)",
                      // },
                    }}
                    className="bttn"
                  >
                    <span className="btt">CONTACT</span>
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <>
              <IconButton
                edge="end"
                color="inherit"
                onClick={toggleDrawer}
                sx={{
                  marginLeft: "auto",
                  color: " #172F49",
                  display: { sm: "block", md: "none" },
                  zIndex: 9999
                }}
              >
                {drawerOpen ? <ClosIcon /> : <MenuIcon />}
              </IconButton>
            </>
          )}
          {/* <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer} sx={{width:'100%'}}>
                        {list}
                    </Drawer> */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer}
            variant="persistent"
          >
            <Paper
              style={{
                width: "100%",
                height: "calc(100% - 64px)",
                marginTop: "67px",
                position: "fixed"
              }}
            >
              {list}
            </Paper>
          </Drawer>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Nav;
