import React from "react";
import OutTeamHeroSection from "./OutTeamHeroSection";
import OurTeamDetails from "./OurTeamDetails";
import OurTeamAchievements from "./OurTeamAchievements";

import { Box } from "@mui/material";
import ContactRefer from "../ContactUs/ContactRefer";

function OurTeamMain() {
  return (
    <Box padding={2}>
      <OutTeamHeroSection />

      <br />

      <div id="teamdetails">
        <br />
        <br />
        <br />
        <br />
        <OurTeamDetails />
      </div>

      <OurTeamAchievements />

      <ContactRefer />
    </Box>
  );
}

export default OurTeamMain;
