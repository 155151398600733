// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Grid,
//   Slide,
// } from "@mui/material";

// // Sample API URL
// const API_URL = "https://octathorn.com/api/team-members";

// const TeamSlider = () => {
//   const [teamData, setTeamData] = useState([]);

//   useEffect(() => {
//     const fetchTeamMembers = async () => {
//       try {
//         const response = await fetch(API_URL);
//         const data = await response.json();
//         setTeamData(data);
//       } catch (error) {
//         console.error("Error fetching team members data", error);
//       }
//     };

//     fetchTeamMembers();
//   }, []);

//   const renderSlider = (teamMembers) => (
//     <Grid container spacing={4} item xs={12} md={12} lg={12}>
//       {teamMembers.map((person, index) => (
//         <Slide
//           direction="up" // Slide from bottom to top
//           in={true}
//           mountOnEnter
//           unmountOnExit
//           key={person._id.$oid}
//         >
//           <Grid
//             item
//             xs={12} // 4 cards per row on larger screens
//             lg={3}
//             md={4}
//             sx={{ padding: "1rem", mt: 8 }}
//           >
//             <Card
//               sx={{
//                 height: "100%",
//                 width: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//                 backgroundColor: "#FFFFFF",
//                 border: "2px solid transparent",
//                 transition: "border-color 0.3s ease-in-out",
//                 "&:hover": {
//                   borderColor: "#62C4EA",
//                   cursor: "pointer",
//                 },
//                 padding: "0.5rem",
//               }}
//             >
//               <Avatar
//                 alt={person.name}
//                 sx={{
//                   width: "130px",
//                   height: "130px",
//                   marginTop: "-5rem",
//                   position: "absolute",
//                   backgroundColor: "#FFFFFF",
//                   border: "2px solid transparent",
//                   boxShadow: "0 0 8px rgba(3, 40, 65, 0.6)", // Add box-shadow on hover
//                   transition: "border-color 0.3s ease-in-out",
//                   "&:hover": {
//                     borderColor: "#62C4EA",
//                     cursor: "pointer",
//                   },
//                 }}
//               >
//                 <img
//                   src={person.photo}
//                   alt={person.name}
//                   style={{
//                     height: "90%",
//                     width: "85%",
//                     transition: "transform 0.3s ease-in-out",
//                     borderRadius: "50%",
//                   }}
//                 />
//               </Avatar>

//               <CardContent sx={{ textAlign: "center", marginTop: "3rem" }}>
//                 <Typography
//                   variant="h6"
//                   sx={{
//                     fontWeight: "600",
//                     fontSize: "24px",
//                     color: "#1B2231",
//                     fontFamily: "Montserrat",
//                   }}
//                 >
//                   {person.name}
//                 </Typography>

//                 <Typography
//                   variant="body2"
//                   sx={{
//                     fontSize: "0.8rem",
//                     color: "#4c5a67",
//                     textAlign: "center",
//                     fontFamily: "Montserrat",
//                     fontWeight: "600",
//                   }}
//                   mt={3}
//                 >
//                   {person.role}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         </Slide>
//       ))}
//     </Grid>
//   );

//   return (
//     <Box sx={{ maxWidth: "auto", padding: "1rem" }}>
//       <Typography
//         variant="h4"
//         align="center"
//         sx={{
//           fontWeight: "600",
//           marginBottom: "3rem",
//           color: "#1B2231",
//           fontFamily: "Montserrat",
//         }}
//       >
//         Our Team
//       </Typography>

//       {teamData.length > 0 && renderSlider(teamData)}
//     </Box>
//   );
// };

// export default TeamSlider;



import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Modal,
  Fade,
  Button,
  Backdrop,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";

// Sample API URL
const API_URL = "https://octathorn.com/api/team-members";

const TeamSlider = () => {
  const [teamData, setTeamData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(API_URL);
        setTeamData(response.data);
      } catch (error) {
        console.error("Error fetching team members data", error);
      }
    };

    fetchTeamMembers();
  }, []);

  const half = Math.ceil(teamData.length / 2);
  const firstHalf = teamData.slice(0, half);
  const secondHalf = teamData.slice(half);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
    responsive: [
     
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1 },
      },
      
    ],
  };

  const sliderSettings2 = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "ease-in-out",
    rtl: true, // Add this property to change the slider direction
    responsive: [
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handleCardClick = (person) => {
    setSelectedPerson(person);
    setModalOpen(true);
  };
  

  const renderCards = (data) =>
    data.map((person) => (
      <Grid key={person._id.$oid} item sx={{ padding: "1rem", mt: 6 }}>
        <Card
          sx={{
            height: "230px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            border: "2px solid transparent",
            boxShadow: "0 0 8px rgba(59, 63, 66, 0.6)",
            transition: "border-color 0.3s ease-in-out",
            "&:hover": {
              borderColor: "#62C4EA",
              cursor: "pointer",
            },
            padding: "1.1rem",
          }}
          onClick={() => handleCardClick(person)}
        >
          <Avatar
            alt={person.name}
            sx={{
              width: "130px",
              height: "130px",
              marginTop: "-5rem",
              position: "absolute",
              backgroundColor: "#FFFFFF",
              border: "2px solid transparent",
              boxShadow: "0 0 8px rgba(3, 40, 65, 0.6)",
              transition: "border-color 0.3s ease-in-out",
              "&:hover": {
                borderColor: "#62C4EA",
                cursor: "pointer",
              },
            }}
          >
            <img
              src={person.photo}
              alt={person.name}
              style={{
                height: "90%",
                width: "85%",
                transition: "transform 0.3s ease-in-out",
                borderRadius: "50%",
              }}
            />
          </Avatar>

          <CardContent sx={{ textAlign: "center", marginTop: "3rem" }}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "600",
                fontSize: "24px",
                color: "#1B2231",
                fontFamily: "Montserrat",
              }}
            >
              {person.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "1rem",
                color: "#4c5a67",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
            >
              
              <span style={{ fontWeight: 'bold', color: '#0E4366' }}>{person.role}</span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ));

    const handleCloseModal = () => setModalOpen(false);

  return (
    <Box sx={{ maxWidth: "auto", padding: "1rem" }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontWeight: "600",
          marginBottom: "3rem",
          color: "#1B2231",
          fontFamily: "Montserrat",
        }}
      >
        Our Team
      </Typography>

      {/* First Row */}
      <Slider {...sliderSettings}>
        {renderCards(firstHalf)}
      </Slider>

      {/* Second Row */}
      <Slider {...sliderSettings2} style={{ marginTop: "2rem" }}>
        {renderCards(secondHalf)}
      </Slider>


 {/* Modal for User Profile */}
 <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "#fff",
              boxShadow: 24,
              padding: 4,
              width: 400,
              borderRadius: 2,
              textAlign: "center",
            }}
          >
            <Avatar
              alt={selectedPerson?.name}
              sx={{
                width: "100px",
                height: "100px",
                margin: "0 auto",
                marginBottom: "1rem",
              }}
            >
              <img
                src={selectedPerson?.photo}
                alt={selectedPerson?.name}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            </Avatar>
            <Typography variant="h6" sx={{ marginBottom: "0.5rem" }}>
              {selectedPerson?.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "#4c5a67", marginBottom: "1rem" }}>
              
              <span style={{ fontWeight: 'bold', color: '#0E4366' }}>{selectedPerson?.role}</span>
            </Typography>
            <Typography variant="body2" sx={{ color: "#4c5a67", textAlign: "center" , marginBottom: "1rem" }}>
              {selectedPerson?.email} {/* Display the bio or any other additional info */}
            </Typography>
            <Typography variant="body2" sx={{ color: "#4c5a67", textAlign: "center" }}>
              {selectedPerson?.description} {/* Display the bio or any other additional info */}
            </Typography>
            <Button
              sx={{ marginTop: "1rem",
                fontWeight: 500,
                fontFamily: "Montserrat",
                backgroundColor: "#0E4366",
                "&:hover": {
                    backgroundColor: "#F6F6F6",
                    color: "#0E4366",
                    fontWeight: 500
                },

               }}
              variant="contained"
              color="primary"
              onClick={handleCloseModal}
              
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>



    </Box>
  );
};

export default TeamSlider;
