import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { InvertedActionButtonStyle } from "../UI/Buttons/ActionButton";
import { Link } from "react-router-dom";

const JoinTalentedComunity = () => {

  const handleScrollToDetails = (event) => {
    event.preventDefault();
    document.querySelector('#what-we-offer').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid
      container
      sx={{
        padding: {
          xs: "0.9rem 1.5rem",
          sm: "4.125rem 1.5rem",
          lg: "4.125rem 5.5rem",
        },
        backgroundColor: "#0E4366",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: "3rem",
          padding: "1rem 1rem",
        }}
      >
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#FFFFFF",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontSize: { lg: "40px", md: "38px", sm: "32px", xs: "20px" },
            fontWeight: 600,
            fontFamily: "Montserrat",
            width: "100%",
          }}
        >
          Join Our Talented Community 
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          sx={{
            fontSize: { lg: "1rem", md: "1rem", sm: "0.875rem", xs: "0.75rem" },
            color: "#FFFFFF",
            fontWeight: 400,
            fontFamily: "Inter",
            paddingTop: 2,
            textAlign: "center",
          }}
        >
            Be part of a team where innovation meets collaboration. At Octathorn, we believe in empowering our people, valuing diverse perspectives, and nurturing creativity. Join us to grow your skills, tackle exciting challenges, and make a real impact in technology.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0rem", // Adjust spacing as needed
        }}
      >
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              ...InvertedActionButtonStyle,
              width: { lg: "170px", md: "170px", sm: "130px", xs: "130px" },
            }}
            className="bttn"
            onClick={handleScrollToDetails}
          >
            <span className="btt">Learn More</span>
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default JoinTalentedComunity;
