import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async'; // Import Helmet
import Nav from "./Components/Header/Nav";
import Footer from "./Components/Footer/Footer";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";

import Services from "./Pages/Services";
import Industries from "./Pages/Industries";

import Company from "./Pages/Company";
import Portfolio from "./Pages/Portfolio";
import AdminPanel from "./Pages/Admin/AdminPanel";
import GetReviews from "./Components/Admin/GetReviewsForm";
import ProjectDetails from "./Pages/ProjectDetails";
import StartTechnologies from "./Components/Technologies/StartTechnologies";

import StartIndustries from "./Components/Industries/StartIndustries";
import Login from "./Pages/Admin/LoginPage";
import PrivateRoutes from "./utils/PrivateRoutes";
import CalendlyWidget from "./Components/ScheduleMeeting/CalendlyWidget";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";

import Sidebar from "./Components/ServicesComponents/ParentServiceComp/Sidebar";
import StartServices from "./Components/ServicesComponents/StartServices";

import Technologies from "./Pages/Technologies";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import PortfolioProjects from "./Components/Industries/IndutryProjects";

import OurTeamMain from "./Components/OurTeam/OurTeamMain"
import CareerMain from "./Components/Career/CareerMain";
import  ReadMore  from "./Components/Career/ReadMore";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <HelmetProvider>
          <Helmet>
            {/* Meta Pixel Code */}
            <script>
              {`
                !function(f,b,e,v,n,t,s){
                  if(f.fbq)return;
                  n=f.fbq=function(){
                    n.callMethod ?
                    n.callMethod.apply(n,arguments) : n.queue.push(arguments)
                  };
                  if(!f._fbq)f._fbq=n;
                  n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];
                  t=b.createElement(e);
                  t.async=!0;
                  t.src=v;
                  s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)
                }(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '1543953316253789');
                fbq('track', 'PageView');
              `}
            </script>
            <noscript>
             {` <img height="1" width="1" style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=1543953316253789&ev=PageView&noscript=1" /> `}
            </noscript>
            {/* End Meta Pixel Code */}
          </Helmet>

          <Nav />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/servicesmain/:heading" element={<StartServices />} />
            <Route path="/industries/:heading" element={<Industries />} />
            <Route path="/industriesmain/:heading" element={<StartIndustries />} />
            <Route path="/about" element={<Company />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/sidebar" element={<Sidebar />} />
            <Route path="/submitreviews" element={<GetReviews />} />
            <Route path="/technologies/:heading" element={<Technologies />} />
            <Route path="/technologiesmain/:heading" element={<StartTechnologies />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/project/:title" element={<ProjectDetails />} />

            <Route path="/ourteam" element={<OurTeamMain />} />
            <Route path="/career" element={<CareerMain />} />
            <Route path= "/career/readmorejobs" element = {<ReadMore/>} />
             {/* <Route path="/project/:title" element={<PortfolioProjects />} /> */}


            <Route path="/adminpanel" element={<PrivateRoutes />}>

              <Route index element={<AdminPanel />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<CalendlyWidget />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
          
          <Footer />
        </HelmetProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
