import { useMediaQuery, Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import topBGTab from "../Assests/home/topBgTab.png";
import topBGMobile from "../Assests/home/topRightbgMobile.png";
import topRightBg from "../Assests/Services/Web/ServiceBg.png";

import { useLocation } from "react-router-dom";

import ContactRefer from "../Components/ContactUs/ContactRefer";
import { useParams } from "react-router-dom";

import {
  ProductService,
  EmbeddedService,
  IotService,
  RDService,
  PcbService,
  TechnicalService,
  WebService,
  MobileService,
  ErpService
} from "../Components/ServicesComponents/SecondServiceComps/ServiceData";

import ParentService from "../Components/ServicesComponents/ParentServiceComp/ParentService";

function StartServices() {
  const { heading } = useParams();
  const [data, setData] = useState(null);
  const getServiceState = () => {
    
    //console.log("....?", heading);
    switch (heading) {
      case "productdevelopmentservices":
        //console.log("....<>", ProductService);
        setData(ProductService);
        //console.log("---->", data);
        break;
      case "embeddedsystems":
        setData(EmbeddedService);
        break;
      case "iotplatformdevelopment":
        setData(IotService);
        break;
      case "research&development":
        setData(RDService);
        break;
      case "pcbdevelopment":
        setData(PcbService);
        break;
      case "technicalconsultation":
        setData(TechnicalService);
        break;
      case "webdevelopmentservices":
        setData(WebService);
        break;
      case "mobiledevelopmentservices":
        setData(MobileService);
        break;
      case "enterpriseresourceplanning":
        setData(ErpService);
        break;
      default:
        case "productdevelopmentservices":
          //console.log("....<>", ProductService);
          setData(ProductService);
        break;
    }
  };

  useEffect(() => {
    getServiceState(heading);
  }, []);

  const location = useLocation();

  //console.log( "location in service comp.. " , location)

 const {state} = location.state || { state: data } || { state: null };

 
 

  // if (state) {
  //   const firstServiceHeading = state[0]?.ServiceHeading[0];

  //   const firstService = state[0];
  //   // //console.log(firstService);
  //   const serviceHeading = firstService?.ServiceHeading;
  //   // //console.log({ serviceHeading });
  // }

  const isMobileView = useMediaQuery("(max-width: 600px)");
  const isTabletView = useMediaQuery("(max-width: 900px)");

  //console.log("stateee in service.. " , state)


  return (
    <Box
      width="100%"
      maxHeight={"90%"}
      height= "90%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      position="relative"
      sx={{
        backgroundColor: "#F6F6F6",
        contain: { xs: "content", sm: "none" }
      }}
    >
      {isMobileView ? (
        <img
          src={topBGMobile}
          alt="Octathorn technologies Hardware & Software"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "0",
            top: 0,
            zIndex: 0
          }}
        />
      ) : isTabletView ? (
        <img
          src={topBGTab}
          alt="Octathorn technologies Hardware & Software"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            top: 0,
            zIndex: 0
          }}
        />
      ) : (
        <img
          src={topRightBg}
          alt="Octathorn technologies Hardware & Software"
          width={"18%"}
          style={{
            display: "flex",
            overflow: "hidden",
            justifyContent: "flex-end",
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 0
          }}
        />
      )}
      <Container
        maxWidth="false"
        sx={{
          padding: { xs: "0rem", sm: "1rem 0rem", lg: "0rem 0rem" },
          zIndex: 1
        }}
      >
        <div>
        {/* {//console.log(" the state for parent service is " , state)}
        {//console.log(" the location for parent service is " , location.state)} */}
          <ParentService state={state || location.state} />
          {/* <ServiceCompWeb state={state || location.state} /> */}
        </div>

        <div
          style={{
            backgroundColor: "#FFF",
            marginLeft: "-2rem",
            marginRight: "-2rem",
            marginBottom: "-1rem"
          }}
        >
          {/* <ContactRefer /> */}
        </div>
      </Container>
    </Box>
  );
}

export default StartServices;
