import React from "react";
import Avatar from "@mui/material/Avatar";
import "./EngagementModel.css";
import "../UI/Buttons/ButtonHover.css";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";

import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  useMediaQuery
} from "@mui/material";
import { Box, height, padding } from "@mui/system";
import tag from "../../Assests/tag.svg";
import hiring from "../../Assests/hiring.svg";
import time from "../../Assests/time-management.svg";
import AfterHeroSection from "./AfterHeroSection";
import StatsCard from "../UI/Cards/StatsCard";

const OutTeamHeroSection = () => {
  const backgroundColors = ["#319AC7", "#0E4366"];
  const isMobileView = useMediaQuery("(max-width: 480px)");
  const isTabletView = useMediaQuery("(max-width: 768px)");

  const handleScrollToDetails = (event) => {
    event.preventDefault();
    document.querySelector('#teamdetails').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Grid
      container
      sx={{
        padding: {
          lg: "3rem 4rem",
          md: "2rem 0rem",
          sm: "2rem 0rem",
          xs: "1rem 1rem"
        },
        backgroundColor: "#F6F6F6"
      }}
    >
      {/* First Section */}
      <Grid
        item
        xs={12}
        sx={{
        
          padding: "1rem 1rem",
          mt: "4rem"
          // border: "1px solid red",
        }}
      >
        <br />
        <br />
        <Typography
          variant="h1"
          component="h1"
          sx={{
            color: "#1B2231",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontSize: { lg: " 2rem", md: "2rem", sm: "1.5rem", xs: "1.5rem" },
            fontWeight: 600,
            fontFamily: "Montserrat",
            Width: "40%"
          }}
        >
          Innovating Together, Building the Future - Where Software and Hardware
          Merge to Shape a Smarter World.
        </Typography>

       
      </Grid>


      <Grid
  item
  xs={12}
  sx={{
    marginBottom: "3rem",
    padding: "1rem 1rem",
    display: "flex", // Ensure Flexbox for alignment
    justifyContent: "center", // Center horizontally
    alignItems: "center" // Center vertically
  }}
>
  <Typography
    variant="subtitle2"
    component="p"
    color="#4C5A67"
    sx={{
      textAlign: "center",
      fontSize: { xl: "18px" ,lg: "15px", sm: "13.7px", xs: "13.7px" },
      fontWeight: 400,
      fontFamily: "Inter",
      padding: 2,
      width: { lg: "60%" , xs: "90%" }
      
    }}
  >
    At Octathorn, our team blends creativity and precision to bring
    cutting-edge software and hardware solutions to life. Together, we
    drive innovation, collaboration, and impact.
  </Typography>
</Grid>

<Grid
        item
        xs={12}
        sx={{
          marginTop: "-6rem",
            padding: "2rem 2rem",
          // border: "1px solid green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {/* <AfterHeroSection/> */}
        <StatsCard />
      </Grid>
     

      {/* Second Section */}

      {/* 3rd Section */}

      <Grid
        item
        xs={12}
        sx={{
          marginTop: "5rem",
          //   padding: "1rem 1rem",
          // border: "1px solid green",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <a
          href="#teamdetails"
          style={{ textDecoration: "none", position: "relative" }}
        >
          <Button sx={ActionButtonStyle} className="bttn" onClick={handleScrollToDetails}>
            <span className="btt">Meet the team</span>
          </Button>
        </a>
      </Grid>
    </Grid>
  );
};

export default OutTeamHeroSection;
