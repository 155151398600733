import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EastIcon from "@mui/icons-material/East";

import Skeleton from "@mui/material/Skeleton";
import { animateScroll as scroll } from "react-scroll";

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Container,
  useMediaQuery,
  Grid,
  Button
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as VerticalLine } from "../../Assests/home/svgs/VerticleLineIndustry.svg";
import { ReactComponent as TickIcon } from "../../Assests/home/svgs/tickIcon.svg";
import RounedButton from "../UI/Buttons/RounedButton";

import "./IndustryBar.css";
//accordian imports
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Styles = {
  list: {
    // width: '100%',
    // maxWidth: 333,
    paddingLeft: { lg: "0rem", md: "0rem", sm: "3rem" },
    left: { lg: "0rem", md: "0rem", xs: "0rem" }
  },
  listItem: {
    paddingLeft: "0%"
  },
  listItemText: {
    paddingLeft: 5,
    textTransform: "uppercase",
    "& .MuiTypography-body1": {
      fontSize: { xs: "0.7rem", sm: "0.7rem", md: "0.75rem", lg: "0.75rem" },
      fontFamily: "Poppins",
      fontWeight: 600,
      fontStyle: "normal"
    }
  }
};

function PortfolioProjects(state) {
  let { heading } = useParams();
  // let heading = 'healthcare'
//console.log("heading in the industry project section..., ",  state)
  // let conditionalHeading = heading
  //   ? heading
  //   : "healthcare" ||
  //     "finance" ||
  //     "defence" ||
  //     "publicsector" ||
  //     "manufacturing" ||
  //     "telecom" ||
  //     "ecommerce";
  // // //console.log("heading", heading);
  // if (conditionalHeading === "healthcare") {
  //   conditionalHeading = "Health Care";
  // }
  // if (conditionalHeading === "publicsector") {
  //   conditionalHeading = "Public Sector";
  // }

  const title1 = `${state?.state?.state?.[0]?.ServiceHeading}`.toLowerCase();

  //console.log("tile in the industry project division .. " , title1)

  const [selectedItem, setSelectedItem] = useState(null);
  const [projectsData, setProjectsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]); // Initialize the items
  const navigate = useNavigate();
  const handleViewMore = (project) => {
    //console.log("state of the industry project ||| " , state)
    //console.log("project of the industry project |||  " , project)
    const titleWithoutSpaces = project.title.replace(/\s/g, "").toLowerCase();
    // navigate(`/project/${titleWithoutSpaces}`, { state: { project } });
    // scroll.scrollToTop({ duration: 2000 });

    const encodedProject = encodeURIComponent(JSON.stringify(project)); // Encode project data into a string
    const url = `/project/${titleWithoutSpaces}?project=${encodedProject}`; // Pass as a query param
  
    // Redirect with full page refresh
    window.location.href = url;  // This triggers the page refresh
  };
  useEffect(() => {
    axios
      .get("https://octathorn.com/api/project")
      // axios.get('https://octathorn.com/api/project')
      .then((response) => {
        // Filter the data based on the heading parameter
        const filteredData = response.data.filter(
          (item) =>
            item.industry.trim().toLowerCase() ===
          title1.trim().toLowerCase()
        );

        const transformedData = filteredData.reduce((acc, item) => {
          if (!acc[item.industry]) {
            acc[item.industry] = [];
          }
          acc[item.industry] = [
            ...acc[item.industry],
            ...item.projects.map((project) => ({
              title: project.title,
              images: project.images,
              description: project.description,
              features: project.features,
              bussinues: project.bussinues,
              challenges: project.challenges,
              solutions: project.solutions,
              objectives: project.objectives,
              technologies: project.technologies,
              category: item.category // Include the 'category' field
            }))
          ];
          return acc;
        }, {});

        //console.log("project portfolio", transformedData);
        setProjectsData(transformedData);
        setSelectedItem(filteredData[0]?.industry);

        // Set the items with the industries from the response
        setItems([...new Set(filteredData.map((item) => item.industry))]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, [title1]); // Add heading to the dependency array
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const themeStyles = {
    heading: {
      fontSize: { lg: "2.5rem", md: "2.5rem", sm: "2rem", xs: "1.25rem" },
      color: "#1B2231",
      textTransform: "capitalize",
      fontWeight: 600
    },
    subtitle: {
      fontSize: { lg: "1rem", md: "1rem", sm: "0.875rem", xs: "0.75rem" },
      color: "#4c5a67",
      fontWeight: 400,
      fontFamily: "Inter",
      paddingTop: 2,
      width: {
        lg: "60.25rem",
        md: "52.25rem",
        sm: "90%",
        xs: "90%"
      },
      marginLeft: "auto",
      marginRight: "auto"
    },
    learnMore: {
      color: "#4c5a67",
      fontFamily: "Inter",
      fontSize: {
        lg: "0.875rem",
        md: "0.875rem",
        sm: "0.875rem",
        xs: "0.75rem"
      },
      fontStyle: "italic",
      fontWeight: 400,
      lineHeight: "1.5625rem",
      cursor: "pointer"
    },
    viewMoreBox: {
      display: "flex",
      textAlign: "center",
      gap: "0.5rem",
      position: "absolute",
      bottom: { md: "0rem", sm: "0rem", xs: "0.5rem" },
      right: { sm: "1rem", xs: "1rem" },
      cursor: "pointer",
      transition: "right 0.3s ease-in-out",
      "&:hover": {
        right: { sm: "0.7rem", xs: "0.8rem" } // Adjust the right position on hover
      }
    },
    viewMore: {
      color: "#319AC7",
      fontFamily: "Montserrat",
      fontSize: {
        lg: "0.875rem",
        md: "0.875rem",
        sm: "0.875rem",
        xs: "0.75rem"
      },
      fontWeight: 600,
      lineHeight: "1.5625rem",
      cursor: "pointer"
    },
    mediaCard: {
      // width: {
      //     lg: "29.4375rem",
      //     md: "30.4375rem",
      //     sm: "9.4375rem",
      //     xs: "18.125rem",
      // },
      //  height: { lg: "24.975rem", md: "20.375rem", sm: "13rem", xs: "7.125rem" },
      display: "flex",
      boxShadow: "none",
      justifyContent: "center",
      alignItems: "center",
      border: "none"
    },
    contentCard: {
      display: "flex",
      justifyContent: "left",
      textAlign: "left",
      backgroundColor: "#FFF",

      height: "100%",
      maxHeight: "30rem",
      width: { xl: "100%", lg: "100%", md: "100%" },

      flexDirection: "column",
      alignItems: "center",
      boxShadow: "none",
      border: "none",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      transition: "background-image 0.3s",
      "&:hover": {
        backgroundImage: `url(${""})`
      }
    },

    cardtitle: {
      fontSize: { lg: "0.9rem", md: "0.9rem", sm: "0.8rem", xs: "0.8rem" },
      color: "#1B2231",
      fontWeight: 600,
      fontStyle: "normal",

      marginTop: "0px",
      lineHeight: "1.96563rem",
      lineHeight: "17px",
      textTransform: "capitalize",
      // width: {
      //     lg: "32rem",
      //     md: "25.875rem",
      //     sm: "19.125rem",
      //     xs: "16.375rem",
      // },
      fontFamily: "Montserrat"
    },
    carddescription: {
      fontSize: { lg: "0.7rem", md: "0.7rem", sm: "0.65rem", xs: "0.65rem" },
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "1.695rem",
      color: "#4c5a67",
      // width: {
      //     lg: "32.0625rem",
      //     md: "25.0625rem",
      //     sm: "21.3125rem",
      //     xs: "13.75rem",
      // },
      marginTop: "1rem",
      lineHeight: "15px",
      fontFamily: "Inter",
      overflow: { sm: "hidden", xs: "hidden", md: "hidden", lg: "hidden" },
      textOverflow: {
        xs: "ellipsis",
        sm: "ellipsis",
        md: "ellipsis",
        lg: "ellipsis"
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: { xs: 4, sm: 4, md: 5, lg: 5 },
      maxHeight: { sm: "6.6em", xs: "6.6em", md: "10.6em", lg: "10.6em" }
    },
    textarea: {
      width: "100%",
      height: "100px",
      color: "#000",
      borderRadius: "17px",
      backgroundColor: "#0E4366"
    },
    productList: {
      color: "#4c5a67",
      fontSize: { lg: "0.7rem", md: "0.7rem", sm: "0.675rem", xs: "0.65rem" },
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "15px",
      lineHeight: "1.695rem",
      fontFamily: "Inter",
      marginLeft: 1,
      overflow: { sm: "hidden", xs: "hidden", md: "hidden", lg: "hidden" },
      textOverflow: {
        xs: "ellipsis",
        sm: "ellipsis",
        md: "ellipsis",
        lg: "ellipsis"
      },
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: { xs: 1, sm: 1, md: 1, lg: 1 }
    },
    accordioHeading: {
      color: "#0E4366",
      fontFamily: "Montserrat",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      textTransform: "uppercase"
    },
    accordionContentDescription: {
      color: "#4c5a67",
      fontFamily: "Inter",
      fontSize: "0.75rem",
      fontStyle: "normal",
      fontWeight: 400
    }
  };
  const isMobileTabletView = useMediaQuery("(max-width: 600px)");
  const selectedData = projectsData[selectedItem] || {};

  return (
    <Container
      maxWidth="lg"
      sx={{
        padding: {
          xs: "0.5rem 1.5rem", // Increased for extra small screens
          sm: "2rem 0rem", // Increased for small screens
          lg: "1rem 0rem" // Increased for large screens
        },
        width: "100%"
      }}
    >
      <Typography
        variant="h1"
        sx={{
          marginBottom: "0.7rem",
          // fontSize: {
          //   lg: "1.875rem",
          //   md: "1.875rem",
          //   sm: "1.375rem",
          //   xs: "1.125rem",
          // },
          color: "#1B2231",
          display: "flex",

          textAlign: "center",
          alignItems: "center",
          fontSize: { lg: "18px", md: "16px", sm: "10px", xs: "4px" },
          fontWeight: 600,
          fontFamily: "Montserrat",
          Width: "100%"
        }}
      >
        Featured Projects
      </Typography>

      {loading ? (
        // <CircularProgress />

        <>
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
          <Skeleton height="60px" animation="wave" />
        </>
      ) : (
        <>
          {!isMobileTabletView ? (
            <Box
              component="div"
              sx={{ ...themeStyles.listContainer, flex: "nowrap" }}
            >
              {/* call industry bar here */}
              {/* <List component="nav" sx={Styles.list}>
                {items.map((item, index) => (
                  <ListItem
                    button
                    key={index}
                    selected={selectedItem === item}
                    onClick={() => handleItemClick(item)}
                    style={Styles.listItem}
                    sx={{
                      width: {
                        lg: "12.9375rem",
                        md: "12.9375rem",
                        sm: "12.625rem"
                      },
                      height: {
                        lg: "2.475rem",
                        md: "2.475rem",
                        sm: "2.175rem"
                      },
                      marginBottom: "2px",
                      color: "#0E4366",
                      backgroundColor:
                        selectedItem === item
                          ? "rgba(14, 67, 102, 0.06)"
                          : "#F6F6F6"
                    }}
                  >
                    

                    <ListItemText primary={item} sx={Styles.listItemText} />
                    <ListItemIcon sx={{ color: "#0E4366", marginLeft: "2px" }}>
                      
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List> */}

              <Grid
                container
                spacing={1}
                ml={"auto"}
                sx={{ maxHeight: "45vh", height: "100%", overflowY: "auto" }}
              >
                {selectedItem &&
                  projectsData[selectedItem].map((project, projectIndex) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={10.5}
                        md={9.5}
                        lg={6}
                        mt={1}
                        sx={{}}
                      >
<Card
  className="card"
  sx={{
    ...themeStyles.contentCard,
    backgroundImage: `url(${project.images[0]})`,
    border: "1px solid #ccc", // Change color as needed
    borderRadius: "8px", // Adjust border radius for a box shape
    position: "relative", // To position the overlay text absolutely
    overflow: "hidden", // To ensure the overlay does not overflow the card
    width: "auto", // Set a fixed width for the card
    height: "255px", // Set a fixed height for the card
    "&:hover .overlay": {
      opacity: 1, // Show overlay text on hover
    },
  }}
>

  {/* Overlay Text */}
  <Box
    className="overlay"
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.8)", // Dark shadow with opacity
      color: "white", // Text color
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      opacity: 0, // Initially hidden
      transition: "opacity 0.3s ease", // Smooth transition for opacity
      textAlign: "center", // Center text
      padding: "1rem", // Optional padding for better spacing
      zIndex: 1, // Ensure overlay is above the content
      cursor: "pointer"
    }}
  >
    <Typography variant="h6">{project.title}</Typography>
    
    {/* Limit the project description */}
    <Typography
      variant="body2"
      sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3, // Limit to 3 lines, change as needed
      }}
    >
      {project.description}
    </Typography>
{/* {//console.log("state of the industry project ||| " , state) }
 {//console.log("project of the only industry project |||  " , project)} */}
    {/* Button */}
    <Box sx={{ marginTop: "1rem" }}>
      <Button
        onClick={() => handleViewMore(project)} // Ensure the button click works
        sx={{
          width: { sm: "170px", xs: "150px" },
          height: { sm: "49px", xs: "43px" },
          fontSize: {
            lg: "15px",
            md: "15px",
            sm: "15px",
            xs: "13px",
          },
          transition: '0.25s',
          "&:hover": {
            backgroundColor: "#F6F6F6",
            borderColor: '#0E4366',
            color: '#0E4366',
            boxShadow: '0 0.5em 0.5em -0.4em rgba(255, 255, 255, 0.7)',
            transform: 'translateY(-0.25em)',
          },
          border: "1px solid #F6F6F6",
          fontFamily: "Montserrat",
          borderWidth: "1px",
          color: "#ffffff",
          textTransform: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2, // Ensure button is above the overlay
        }}
      >
        View More
      </Button>
    </Box>
  </Box>
</Card>





                        
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Box>
          ) : (
            // </Box>
            <Box mt={5}>
              {Object.entries(projectsData).map(
                ([industry, projects], index) => (
                  <Accordion
                    key={index}
                    style={{
                      minHeight: "4.375rem"
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon style={{ color: "#0E4366" }} />
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={themeStyles.accordioHeading}>
                        {industry}
                      </Typography>
                    </AccordionSummary>
                    {projects.map((data, projectIndex) => (
                      <AccordionDetails key={projectIndex}>
                        <Typography
                          variant="h3"
                          sx={{ ...themeStyles.cardtitle, textAlign: "left" }}
                        >
                          {data.title}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            ...themeStyles.accordionContentDescription,
                            textAlign: "left"
                          }}
                        >
                          {data.description}
                        </Typography>
                        {/* Products LISTS*/}
                        <Box
                          sx={{
                            // display: "grid",
                            // gridTemplateColumns: "repeat(2, 1fr)",
                            display: "flex",
                            flexDirection: "column",
                            // gap: "20px",
                            marginTop: "10%"
                          }}
                        >
                          {data?.features
                            ?.slice(0, 2)
                            ?.map((feature, index) => {
                              const trimmedFeature = feature
                                .split(":")[0]
                                .trim();
                              return (
                                <Box
                                  key={index}
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <TickIcon />
                                  <Typography sx={themeStyles.productList}>
                                    {trimmedFeature}
                                  </Typography>
                                </Box>
                              );
                            })}
                        </Box>
                        {/*LISTS*/}
                        <Box
                          sx={{
                            marginLeft: "1%",
                            marginTop: "2%",
                            width: "100%",
                            contain: "content"
                          }}
                        >
                          <Card sx={{ ...themeStyles.card }}>
                            {/* <CardMedia
                                            component="img"
                                            height="200"
                                            image={data.images[0]} // Assuming you want to display the first image
                                            alt={"IMAGE"}
                                        /> */}

                            <Carousel
                              showThumbs={false}
                              showStatus={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              interval={3000}
                              showArrows={false}
                            >
                              {data.images?.map((image, index) => (
                                <div key={index}>
                                  <img
                                    src={image}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover"
                                    }}
                                    alt={`IMAGE ${index}`}
                                  />
                                </div>
                              ))}
                            </Carousel>
                          </Card>
                        </Box>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                )
              )}
            </Box>
          )}
        </>
      )}
    </Container>
  );
}

export default PortfolioProjects;
