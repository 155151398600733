export const ActionButtonStyle = {
    width: "auto",
    height: { lg: "49px", md: "49px", sm: "38px", xs: "33px" },
    fontSize: { lg: "15px", md: "15px", sm: "12px", xs: "12px" },
    padding: "1rem",
    fontWeight: 500,
    fontFamily: "Montserrat",
    backgroundColor: "#0E4366",
    "&:hover": {
        backgroundColor: "#F6F6F6",
        color: "#0E4366",
        fontWeight: 500
    },
    borderRadius: "4px",
    color: "#ffffff",
    textTransform: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "#00000026",
    position: "relative" // Added position relative here
}


export const NewInvertedActionButtonStyle = {
    width: "auto",
    height: { lg: "49px", md: "49px", sm: "38px", xs: "33px" },
    fontSize: { lg: "15px", md: "15px", sm: "12px", xs: "12px" },
    padding: "1rem",
    fontWeight: 500,
    fontFamily: "Montserrat",
    backgroundColor: "#F6F6F6", // Inverted background
    "&:hover": {
        backgroundColor: "#0E4366", // Hover should also be inverted
        color: "#ffffff", // Text color should now match the background
        fontWeight: 500
    },
    borderRadius: "4px",
    color: "#0E4366", // Text color is now the original background color
    textTransform: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "#00000026",
    position: "relative" // Added position relative here
}


export const InvertedActionButtonStyle = {


        width: { sm: "170px", xs: "150px" },
        height: { sm: "49px", xs: "43px" },
        fontSize: {
          lg: "15px",
          md: "15px",
          sm: "15px",
          xs: "13px",
        }, transition: '0.25s',
        "&:hover": {
          backgroundColor: "#F6F6F6",
          borderColor: '#0E4366',
          color: '#0E4366',
          boxShadow: '0 0.5em 0.5em -0.4em rgba(255, 255, 255, 0.7)',
          transform: 'translateY(-0.25em)'

        },
        border: "1px solid #F6F6F6",
        fontFamily: "Montserrat",
        borderWidth: "1px",
        color: "#ffffff",
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
 
}