import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  Container,
  Divider,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const OpenPositionSection = () => {
  const [jobs, setJobs] = useState([]);

  // console.log("here are the jobs in open position section " , jobs)

  const navigate = useNavigate();

  const ReadMoreNavigation = (job) => {
      navigate("/career/readmorejobs", { state: { job} });
  };

  // Fetch job data from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://octathorn.com/api/jobs');
        setJobs(response.data); // Set the fetched jobs data
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
    };

    fetchJobs();
  }, []);

  return (
    <Container>
      {/* Centered Typography */}
      <Typography
        variant="h4"
        align="center"
        sx={{ 
          fontWeight: "600", 
          marginBottom: "3rem", 
          color: "#1B2231", 
          fontFamily: "Montserrat",
          marginTop: "2rem"  // Adding space above to make it appear centered
        }}
      >
        Open Positions
      </Typography>

      {/* Grid */}
      <Grid container spacing={4}>
        {jobs.map((job) => (
          <Grid item xs={12} sm={6} md={6} key={job.id}>
            <Card sx={{ height: {lg:550 , xs:603}, position: "relative" }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <CardMedia
                  component="img"
                  image={job.image}
                  alt={job.title}
                  sx={{
                    width: "100%",
                    height: 380,
                    objectFit: "cover",
                    borderRadius: 1,
                  }}
                />
                <CardContent
                  sx={{
                    textAlign: "left",
                    width: "100%",
                    flexGrow: 1, // Ensure it stretches
                  }}
                >
                  <Typography variant="h6" component="div" 
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,                
                    }}
                  >
                    {job.jobTitle}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" 
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      
                    }}
                  >
                    {job.description}
                  </Typography>
                

                </CardContent>
                <Button
                  variant="contained"
                  // href={`/job/${job.id}`}
                  sx={{
                    position: "absolute",
                    bottom: 16, // Position from the bottom
                    left: 16, // Position from the left
                    backgroundColor: "#0E4366", // Default background color
                    color: "white", // Default text color
                    "&:hover": {
                      backgroundColor: "white", // Change background color on hover
                      color: "#0E4366", // Change text color to button's color on hover
                    },
                  }}
                
                  
                  onClick={() => ReadMoreNavigation(job)}
                >
                  Read More
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default OpenPositionSection;
