import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  Container,
  Fade,
} from "@mui/material";

import { Box, } from "@mui/system";
import { ReactComponent as IEPHome } from "../../Assests/OurTeam/Process.svg";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";
import ServiceLaptopImg from "../../Assests/Services/Web/ServiceLaptopImg.png";
import ServiceTabImg from "../../Assests/Services/Web/ServiceTabImg.png";
import ServiceMobileImg from "../../Assests/Services/Web/ServiceMobileImg.png";

import Process from "../../Assests/OurTeam/Process.png"

import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function ProcessIsEasy(   ) {

    const location = useLocation();
    const { job } = location.state || {};

    const [detailJob , setDetailJob] = useState(job);


    const [currentTime, setCurrentTime] = useState(new Date());
    const [timeRemaining, setTimeRemaining] = useState("");
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000);
  
      const updateTimeRemaining = () => {
        const dueDate = new Date("2024-12-30T00:00:00.000Z");
        const now = new Date();
        const diff = dueDate - now;
        
        if (diff <= 0) {
          setTimeRemaining("Time is up!");
        } else {
          const days = Math.floor(diff / (1000 * 60 * 60 * 24));
          const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((diff / (1000 * 60)) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          setTimeRemaining(
            `${days}d ${hours}h ${minutes}m ${seconds}s`
          );
        }
      };
  
      const timerInterval = setInterval(updateTimeRemaining, 1000);
      updateTimeRemaining();
  
      return () => {
        clearInterval(interval);
        clearInterval(timerInterval);
      };
    }, []);

    useEffect(() => {
        const scrollToTop = () => {
          const duration = 500; // Duration in milliseconds
          const startPosition = window.pageYOffset;
          const distance = startPosition;
          let startTime = null;
    
          const animateScroll = currentTime => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            const easeInOutCubic = (t) => --t * t * t + 1; // Easing function
            window.scrollTo(0, startPosition - distance * easeInOutCubic(progress));
    
            if (timeElapsed < duration) {
              requestAnimationFrame(animateScroll);
            }
          };
    
          requestAnimationFrame(animateScroll);
        };
    
        scrollToTop();
      }, []);
    


// console.log("here are the job details in this component " , detailJob)


  const isLG = useMediaQuery("(min-width: 1280px)");
  const isMD = useMediaQuery("(min-width: 900px) and (max-width: 1279px)");
  const isSM = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
  const isXS = useMediaQuery("(max-width: 599px)");

  //............
  const isMobileView = useMediaQuery("(max-width: 600px)");
  const isTabletView = useMediaQuery("(max-width: 900px)");
  const isVvSmallLaptop = useMediaQuery("(max-width: 999px)");
  const isVerySmallLaptop = useMediaQuery("(max-width: 1450px)");
  const isSmallLaptop = useMediaQuery("(max-width: 1650px)");
  const widthValue = isVvSmallLaptop
    ? "30rem"
    : isVerySmallLaptop
      ? "30rem"
      : isSmallLaptop
        ? "30rem"
        : "35rem";

  const marginLeftValue = isVvSmallLaptop
    ? "1rem"
    : isVerySmallLaptop
      ? "7rem"
      : isSmallLaptop
        ? "10rem"
        : "6rem";


  let ServiceImage;

  if (isLG || isMD) {
    ServiceImage = job.image;
  } else if (isSM) {
    ServiceImage = job.image;
  } else if (isXS) {
    ServiceImage = job.image;
  }
  return (
    <Grid
      container
      sx={{
        padding: {
          lg: "2rem 0rem",
          md: "2rem 0rem",
          sm: "2rem 1rem",
          xs: "2rem 1rem",
        },
        marginTop: { lg: "5rem", md: "4rem", sm: "4rem", xs: "2rem" },
      }}
    >
      {/* <Box border={"2px solid blue"}> */}

      {/* </Box> */}
      <Grid
        item
        container
        lg={6}
        md={6}
        sm={6}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { lg: "1.1rem", md:"1.1rem" ,  sm: "1.1rem", xs: "1rem" },
          alignItems: { lg: "start", md: "start", sm: "start", xs: "center" },
          paddingLeft: { lg: "3rem", md: "3rem", sm: "1rem", xs: "0rem" },
          marginTop: "1rem",
          justifyContent: {
            lg: "center",
            md: "center",
            sm: "start",
            xs: "center",
          },

          // border: "2px solid black",
        }}
      >
        {/* <Typography
          component="h1"
          sx={[
            {
              color: "#1B2231",
              fontSize: { lg: "2.5rem", md: "2.3rem", sm: "2rem", xs: "1.25" },
              fontWeight: "600",
              fontFamily: "Montserrat",
              textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
              textTransform: "uppercase",
              animation: "slideInFromLeft 0.8s ease-in-out",
              "@keyframes slideInFromLeft": {
                from: {
                  transform: "translateX(-100%)",
                  opacity: 0,
                },
                to: {
                  transform: "translateX(0)",
                  opacity: 1,
                },
              },


            },
          ]}
        >
          New
        </Typography> */}

        {/* Timer and Clock */}
       

        <Typography
          component="h1"
          sx={{
            borderRadius: "1.8125rem 0.375rem",
            backgroundColor: "#0E4366",
            color: "#fff",
            width: "auto",
            height: "auto",
            padding: "0.18rem 1.2rem",
            fontFamily: "Montserrat",
            fontSize: { lg: "2.5rem", md: "2.3rem", sm: "2rem", xs: "1.25" },
            fontStyle: "normal",
            fontWeight: 600,
            marginLeft: "-1rem",
            marginTop: { lg: "0rem", md: "0rem", sm: "0rem", xs: "0rem" },
            animation: "slideInFromLeft 0.8s ease-in-out",
            "@keyframes slideInFromLeft": {
              from: {
                transform: "translateX(-100%)",
                opacity: 0,
              },
              to: {
                transform: "translateX(0)",
                opacity: 1,
              },
            },

          }}
        > {job.jobTitle}
        </Typography>

        <Typography
          sx={{
            fontSize: { lg: "16px", md: "14px", sm: "14px", xs: "12px" },
            fontWeight: 500,
            fontFamily: "Inter",
            color: "#0E4366",
            textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
          
          }}
        >
           <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Due Date: </span>{new Date(job?.dueDate).toLocaleDateString()} at{" "}
          {new Date(job?.dueDate).toLocaleTimeString()}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "16px", md: "14px", sm: "14px", xs: "12px" },
            fontWeight: 500,
            fontFamily: "Inter",
            color: "#FF0000",
            textAlign: { lg: "left", md: "left", sm: "left", xs: "center" },
            
          }}
        >
           <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Time Remaining: </span> {timeRemaining}
        </Typography>

        {/* Button */}

        <Typography
          color="#4c5a67"
          component="p"

          sx={{
            width: { lg: "73%", md: "85%" },
            fontSize: { lg: "16px", sm: "14px", xs: "12px" },
            fontWeight: 400,
            fontFamily: "Inter",
            textAlign: { lg: "left", sm: "left", xs: "center" },
            //   display: { xs: "none", sm: "block" },
            // paddingBottom: { lg: "2rem", md: "3rem", sm: "2rem", xs: "1rem" },
            animation: "slideInFromLeft 0.8s ease-in-out",
            "@keyframes slideInFromLeft": {
              from: {
                transform: "translateX(-100%)",
                opacity: 0,
              },
              to: {
                transform: "translateX(0)",
                opacity: 1,
              },
            },

          }}
        >
            <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Description: </span>  {job.description}
      </Typography>


      <Typography
          color="#4c5a67"
          component="p"

          sx={{
            width: { lg: "73%", md: "85%" },
            fontSize: { lg: "16px", sm: "14px", xs: "12px" },
            fontWeight: 400,
            fontFamily: "Inter",
            textAlign: { lg: "left", sm: "left", xs: "center" },
            //   display: { xs: "none", sm: "block" },
            // paddingBottom: { lg: "2rem", md: "3rem", sm: "2rem", xs: "1rem" },
            animation: "slideInFromLeft 0.8s ease-in-out",
            "@keyframes slideInFromLeft": {
              from: {
                transform: "translateX(-100%)",
                opacity: 0,
              },
              to: {
                transform: "translateX(0)",
                opacity: 1,
              },
            },

          }}
        >
           <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Qualifications Required: </span>  {job.qualifications || "Not Mentioned"}
      </Typography>

      <Typography
          color="#4c5a67"
          component="p"

          sx={{
            width: { lg: "73%", md: "85%" },
            fontSize: { lg: "16px", sm: "14px", xs: "12px" },
            fontWeight: 400,
            fontFamily: "Inter",
            textAlign: { lg: "left", sm: "left", xs: "center" },
            //   display: { xs: "none", sm: "block" },
            // paddingBottom: { lg: "2rem", md: "3rem", sm: "2rem", xs: "1rem" },
            animation: "slideInFromLeft 0.8s ease-in-out",
            "@keyframes slideInFromLeft": {
              from: {
                transform: "translateX(-100%)",
                opacity: 0,
              },
              to: {
                transform: "translateX(0)",
                opacity: 1,
              },
            },

          }}
        >
           <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Experience : </span>  {job.experience || "Not Mentioned"}
      </Typography>



<Typography
  color="#4c5a67"
  component="div"
  sx={{
    width: { lg: "73%", md: "85%" },
    fontSize: { lg: "16px", sm: "14px", xs: "12px" },
    fontWeight: 400,
    fontFamily: "Inter",
    textAlign: { lg: "left", sm: "left", xs: "center" },
    // paddingBottom: { lg: "2rem", md: "3rem", sm: "2rem", xs: "1rem" },
    animation: "slideInFromLeft 0.8s ease-in-out",
    "@keyframes slideInFromLeft": {
      from: {
        transform: "translateX(-100%)",
        opacity: 0,
      },
      to: {
        transform: "translateX(0)",
        opacity: 1,
      },
    },
    ul: {
      padding: 0,
      listStyleType: "none",
      margin: 0,
    },
    li: {
      position: "relative",
      paddingLeft: "1.5rem",
      marginBottom: "1rem",
      "&::before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: "0.5rem",
        width: "0.8rem",
        height: "0.8rem",
        backgroundColor: "#007BFF",
        borderRadius: "50%",
      },
    },
  }}
> <span style={{ fontWeight: 'bold', color: '#007BFF' }}>Requirements: </span> 
  <ul>
    <br/>
    {job.summary
      .split("•") // Split the string into an array
      .filter(item => item.trim() !== "") // Remove empty strings or unnecessary spaces
      .map((point, index) => (
        <li key={index}>{point.trim()}</li> // Render each point as a list item
      ))}
  </ul>
</Typography>



      <a href="mailto:jobs@octathorn.com" style={{ textDecoration: 'none' }}>
  <Fade in={true} timeout={3000}>
    <Button
      sx={{ ...ActionButtonStyle, width: { lg: "170px", md: "170px", sm: "130px", xs: "130px" } }}
      className="bttn"
    >
      <span className="btt">Apply Now</span>
    </Button>
  </Fade>
</a>

      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        sx={{
          // gap: "15px",
          display: {
            lg: "flex",
            md: "flex",
            sm: "flex",
            xs: "flex",
          },
          flexDirection: "column",
          alignItems: {
            lg: "center",
            md: "center",
            sm: "center",
            xs: "center",
          },
          justifyContent: {
            lg: "center",
            md: "center",
            sm: "center",
            xs: "center",
          },
          marginTop: { lg: "-1rem", md: "-1rem", sm: "-1rem", xs: "3rem" },
          // border: "2px solid red",
        }}
      >
        {!isMobileView && (
          <Fade in={true} timeout={3000}>
            <Box
              display="flex"
              sx={{
                flexDirection: "column",
                // border: "2px solid red",
                marginRight: { lg: "4rem", md: "4rem" },
                marginLeft: { lg: "0rem", md: "0rem" , sm:"0rem" },
                marginTop: { lg: "-7rem", md: "-2rem" },
              }}
            >
              {/* <IEPHome width={widthValue} /> */}
              {job?.image && (
  <Fade in={true} timeout={3000}>
    <Box
      display="flex"
      sx={{
        flexDirection: "column",
        marginRight: { lg: "4rem", md: "10rem" },
        marginLeft: { lg: "0rem", md: "-8rem" },
        marginTop: { lg: "7rem", md: "-2rem" },
      }}
    >
      <img 
        src={job.image} 
        alt="Job Image" 
        style={{ width: widthValue, marginLeft: marginLeftValue , maxWidth: "100%", borderRadius: "15px" }} 
      />
    </Box>
  </Fade>
)}

            </Box>
          </Fade>
        )}
      </Grid>

    </Grid>
  );
}

export default ProcessIsEasy;
