import React from "react";
import { Box, Grid, Card, CardMedia } from "@mui/material";

import Octathorn_Team_1 from "../../Assests/OurTeam/Octathorn_Team_1.png";

const GalleryComponent = () => {
  // Use the imported image directly
  const gridImages = [
    Octathorn_Team_1,
    Octathorn_Team_1,
    Octathorn_Team_1,
    Octathorn_Team_1
  ];

  return (
    <Box sx={{ flexGrow: 1, maxWidth: "100%", padding: 2 }}>
      {/* Top Big Image */}
      <Card sx={{ marginBottom: 2, padding: 0 }}>
        <CardMedia
          component="img"
          sx={{
            height: 400,
            objectFit: "cover",
            width: "100%"
          }}
          image={Octathorn_Team_1}
          alt="Top Big Image"
        />
      </Card>

      {/* Grid of 4 Images */}
      <Grid container spacing={2}>
        {gridImages.map((image, index) => (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                sx={{ objectFit: "cover" }} // Ensures image fills card
                image={image}
                alt={`Grid Image ${index + 1}`}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GalleryComponent;
