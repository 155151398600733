import { useMediaQuery, Box, Container } from "@mui/material";
import topBGTab from "../Assests/home/topBgTab.png";
import topBGMobile from "../Assests/home/topRightbgMobile.png";
import topRightBg from "../Assests/Services/Web/ServiceBg.png";

import { useLocation, useParams } from "react-router-dom";

import ContactRefer from "../Components/ContactUs/ContactRefer";
import { useEffect, useState } from "react";

import {
  HealthCare,
  Finance,
  Defence,
  PublicSector,
  Telecom,
  Manufacturing,
  Ecommerce
} from "../Components/Industries/IndustryData";

import ParentIndustries from "../Components/Industries/ParentIndustries";

function Industries() {
  const { heading } = useParams();
  const [data, setData] = useState(null);
  const getServiceState = () => {
    switch (heading) {
      case "healthcare":
        //console.log("....<>", HealthCare);
        setData(HealthCare);
        //console.log("---->", data);
        break;
      case "embeddedsystems":
        setData(Finance);
        break;
      case "iotplatformdevelopment":
        setData(Defence);
        break;
      case "research&development":
        setData(PublicSector);
        break;
      case "pcbdevelopment":
        setData(Telecom);
        break;
      case "technicalconsultation":
        setData(Manufacturing);
        break;
      case "webdevelopmentservices":
        setData(Ecommerce);
        break;
      default:
        case "healthcare":
          //console.log("....<>", HealthCare);
          setData(HealthCare);
        break;
    }
  };

  useEffect(() => {
    getServiceState(heading);
  }, []);



  const location = useLocation();

  const { state } = location.state || { state: data } || { state: null };

 

  // if (state) {
  //   const firstServiceHeading = state[0]?.ServiceHeading[0];

  //   const firstService = state[0];
  //   // //console.log(firstService);
  //   const serviceHeading = firstService?.ServiceHeading;
  //   // //console.log({ serviceHeading });
  // }

  const isMobileView = useMediaQuery("(max-width: 600px)");
  const isTabletView = useMediaQuery("(max-width: 900px)");
  return (
    <>
      <Box
        width="100%"
         maxHeight={"90%"}
      height= "90%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        sx={{
          backgroundColor: "#F6F6F6",
          contain: { xs: "content", sm: "none" }
        }}
      >
        {isMobileView ? (
          <img
            src={topBGMobile}
            alt="Octathorn technologies Hardware & Software"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              right: "0",
              top: 0,
              zIndex: 0
            }}
          />
        ) : isTabletView ? (
          <img
            src={topBGTab}
            alt="Octathorn technologies Hardware & Software"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "absolute",
              top: 0,
              zIndex: 0
            }}
          />
        ) : (
          <img
            src={topRightBg}
            alt="Octathorn technologies Hardware & Software"
            width={"18%"}
            style={{
              display: "flex",
              overflow: "hidden",
              justifyContent: "flex-end",
              position: "absolute",
              right: 0,
              top: 0,
              zIndex: 0
            }}
          />
        )}
        <Container
          maxWidth="false"
          sx={{
            padding: { xs: "0rem", sm: "1rem 0rem", lg: "0rem 0rem" },

            zIndex: 1
          }}
        >
          {/* {//console.log(" the state in parent industry is " , state)}
          {//console.log(" the location in parent industry is " , location.state)} */}
          <ParentIndustries state={state || location.state } />

          <div
            style={{
              backgroundColor: "#FFF",
              marginLeft: "-2rem",
              marginRight: "-2rem",
              marginBottom: "-1rem"
            }}
          >
            {/* <ContactRefer /> */}
          </div>
        </Container>
      </Box>
    </>
  );
}

export default Industries;
