import React from "react";
import {
  Box,
  Typography,
  Container,
  dividerClasses,
  useMediaQuery,
} from "@mui/material";
import ImageSlider from "../UI/Sliders/ImageSlider";
import StatsCard from "../UI/Cards/StatsCard";

function AfterHeroSection() {
  const isLargeScreen = useMediaQuery("(min-width: 600px)");
  return (
    <Box padding={2}>
      {/*<Box width="100%" display="flex" flexDirection="column" alignItems="center" >
        <Container maxWidth="false" sx={{ padding: {xs:'0.9rem 1.5rem',sm: '4.125rem 1.5rem',lg:'4.125rem 4.5rem'} }}>*/}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "2%",
          marginBottom: "0%",
        }}
      >
   
      </Box>
    
    

     
      <StatsCard />
      {/* </Container>
        </Box> */}
    </Box>
  );
}
export default AfterHeroSection;
