import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import IndustryLaptopImg from "../../Assests/Industries/IndustDesktop.png";
import IndustryMobImg from "../../Assests/Industries/IndustDesktopMob.png";

import checkbox from "../../Assests/Services/Web/checkbox.png";

import { Helmet } from "react-helmet-async";
import { ActionButtonStyle } from "../UI/Buttons/ActionButton";

function IndustriesCompSub(state) {
  // let { heading } = useParams();

  // let {heading} = "healthcare"

  // console.log("industries component ........... " , state)

  // if (heading === "healthcare") {
  //   heading = "HEALTH CARE";
  // } else if (heading === "publicsector") {
  //   heading = "PUBLIC SECTOR";
  // } else if (heading === "finance") {
  //   heading = "FINANCE";
  // } else if (heading === "defence") {
  //   heading = "DEFENCE";
  // } else if (heading === "telecom") {
  //   heading = "TELECOM";
  // } else if (heading === "manufacturing") {
  //   heading = "MANUFACTURING";
  // } else if (heading === "ecommerce") {
  //   heading = "ECOMMERCE";
  // }

  const title1 = `${state?.state?.[3]?.ServiceTitle1}`.toLowerCase();
  const title2 = `${state?.state?.[4]?.ServiceTitle2}`.toLowerCase();

  const capitalizeFirstLetter = (str) => {
    const words = str.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  };
  const capitalizedTitle1 = capitalizeFirstLetter(title1);
  const capitalizedTitle2 = capitalizeFirstLetter(title2);
  const combinedTitle = `${capitalizedTitle1} ${capitalizedTitle2}`;

  const headingWithoutSpaces = combinedTitle.replace(/\s/g, "").toLowerCase();

 // console.log("headingWithoutSpaces   .. " , headingWithoutSpaces)

  const isLG = useMediaQuery("(min-width: 1280px)");
  const isMD = useMediaQuery("(min-width: 900px) and (max-width: 1279px)");
  const isSM = useMediaQuery("(min-width: 600px) and (max-width: 900px)");
  const isXS = useMediaQuery("(max-width: 599px)");

  let IndustryImage;

  if (isLG) {
    IndustryImage = IndustryLaptopImg;
  } else if (isSM || isMD) {
    IndustryImage = IndustryMobImg;
  } else if (isXS) {
    IndustryImage = IndustryMobImg;
  }

  const industryDescriptions =
    state?.state?.state?.[5]?.ServiceTitleDescription;

  return (
    <Grid
      container
      sx={{
        padding: {
          lg: "0rem 0rem",
          md: "0rem 0rem",
          sm: "5rem 1rem",
          xs: "5rem 1rem"
        },
        // marginTop: "5rem"
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "2rem"
        }}
      >
        <Helmet>
          {/* {console.log("Industry stateeeeee ...........", state?.state?.state)} */}
          {/* <title>{`Octathorn - ${combinedTitle}`}</title> */}
          <link
            rel="canonical"
            href={`https://octathorn.com/industries/${headingWithoutSpaces}`}
          />
          <meta
            name="description"
            content={`   At Octathorn, we drive forward with relentless innovation, embracing technology to redefine possibilities. We specialize in crafting seamless user experiences through our mastery in Front-End and Back-End development, ensuring reliability and innovation in every project. Our commitment to technological excellence fuels our drive to innovate across Embedded Systems, IoT, PCB, Front-End, Back-End, Mobile, and Database technologies. `}
          />

          {/* <meta name="description" content={`${state?.state?.state?.[6]?.ServiceTitle1}  ${state?.state?.state?.[7]?.ServiceTitle2} ${state?.state?.state?.[8]?.ServiceTitleDescription}`} /> */}
          <meta
            name="keywords"
            content="Unlock the future trusted next-generation hardware software solutions quality focus customer-centric insights-driven 20+ happy clients hardware embedded systems software scalability product development IoT platform development research and development PCB design technical consultation web applications mobile applications ERP development industry expertise transformative growth innovative vision tailored solutions user-centric approach efficient integration domain expertise cost-effective solutions diverse service offerings embedded systems IoT software development real-time AI computer vision UI/UX product development technology and platform stack Raspberry Pi LoRa ESP32 FPGA Arduino Azure IoT Hub AWS IoT Agile Methodology adaptability technological excellence client-centric approach continuous support and updates high client retention efficient integration transparent communication"
          />
        </Helmet>

        <Typography
          component="h1"
          sx={{
            // fontFamily: "Inter", // Changed font family to Inter
            // fontSize: { lg: "32px", md: "32px", sm: "32px", xs: "32px" }, // Set font size to 32px for all screen sizes
            // fontWeight: 600, // Font weight remains 600
            // textAlign: "left", // Set text alignment to left
            // margin: "1rem 0",
            // color: "#0E4366", // Changed color to #0E4366
            // fontSize: "20px"
            fontFamily: "Inter", // Changed font family to Inter
            fontSize: { lg: "32px", md: "32px", sm: "32px", xs: "32px" }, // Set font size to 32px for larger screens
            fontWeight: 600, // Font weight remains 600
            textAlign: "left", // Set text alignment to left
            margin: "1rem 0",
            color: "#0E4366", // Changed color to #0E4366
            fontSize: "20px", // Ensure font size is defined correctly
            width: "100%", // Ensure the Typography takes full width to apply left alignment
            marginLeft: "2rem"
          }}
        >
         {state?.state?.state?.[3]?.ServiceTitle1}{" "}
         {state?.state?.state?.[4]?.ServiceTitle2}
        </Typography>

        <div style={{ marginTop: "0rem" }}>
          {/* <Typography
            variant="subtitle2"
            component="h2"
            color="#4C5A67"
            sx={{
              fontSize: { lg: "16px", sm: "14px", xs: "12px" },
              fontWeight: 400,
              fontFamily: "Inter"
            }}
          >
            {state?.state?.state?.[8]?.ServiceTitle1?.[0]}{" "}
            {state?.state?.state?.[8]?.ServiceTitle2?.[0]}
          </Typography> */}
          <ul
            style={{
              listStyle: "none", // Remove default bullet points
              paddingLeft: "10px",
              marginTop: "0rem",
              textAlign: "left"
            }}
          >
            {industryDescriptions?.map((description, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center" // Use 'center' to align both image and text vertically
                }}
              >
                {/* Image as bullet */}
                {/* <img
                  src={checkbox} // Replace with the correct path to your image
                  alt="checkbox"
                  style={{
                    width: "16px", // Set size for your checkbox image
                    height: "16px",
                    marginRight: "8px" // Add some space between the image and text
                  }}
                /> */}
                <Typography
                  variant="subtitle2"
                  component="p"
                  color="#4C5A67"
                  sx={{
                    fontSize: { lg: "12px", sm: "10px", xs: "9px" },
                    fontWeight: 400,
                    fontFamily: "Inter",
                    marginRight: "0.5rem" , marginLeft: "0.5rem"
                  }}
                >
                  {description}
                </Typography>
              </li>
            ))}
          </ul>
        </div>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "0rem",
            alignItems: "center"
          }}
        >
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Box  sx={{
      display: "inline-block", // To ensure the zoom effect works correctly
      transition: "transform 0.3s ease", // Transition effect for smooth scaling
      "&:hover": {
        transform: "scale(1.05)", // Scale effect on hover
        transition: "transform 0.3s ease"
      },
      cursor: "pointer", // Change cursor to pointer
    }}
    onClick={() => {
      // Handle click event here, e.g., open a new page or show a modal
     // console.log("Image clicked!");
    }}>
            <img
              src={IndustryImage}
              alt={state?.state?.state?.[6]?.ServiceTitle1}
              style={{ widht: "auto", height: "147px", alignItems: "center"  }}
            />

</Box>
          </Grid>
          {/* Add more images here if needed */}
        </Grid>

        {/* Div for bullet points added....... */}

       {/* Button */}
       <a href="/contact" style={{ textDecoration: "none", paddingTop: "4.2rem" }}>
  <Button
    sx={{ ...ActionButtonStyle, width: { lg: "150px", md: "150px", sm: "130px", xs: "130px" },
    height: { lg: "40px", md: "40px", sm: "35px", xs: "30px" }}
   }
   className="bttn"
    // sx={{
    //   width: { sm: "140px", xs: "120px" }, // Decreased width
    //   height: { sm: "40px", xs: "35px" }, // Decreased height
    //   fontSize: {
    //     lg: "14px",
    //     md: "14px",
    //     sm: "14px",
    //     xs: "12px",
    //   },
    //   backgroundColor: "#0E4366", // Changed background color
    //   color: "#ffffff", // Set text color to white
    //   transition: '0.25s',
    //   "&:hover": {
    //     backgroundColor: "#0E4366", // Maintain hover color
    //     borderColor: '#F6F6F6', // Border color on hover
    //     color: '#F6F6F6', // Change text color on hover
    //     boxShadow: '0 0.5em 0.5em -0.4em rgba(255, 255, 255, 0.7)',
    //     transform: 'translateY(-0.25em)',
    //   },
    //   border: "1px solid #F6F6F6", // Maintain border color
    //   fontFamily: "Montserrat",
    //   borderWidth: "1px",
    //   textTransform: "none",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // }}
  >
    Get in Touch
  </Button>
</a>

        
      </Grid>
    </Grid>
  );
}

export default IndustriesCompSub;
